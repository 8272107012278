import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/Shared/user.service';

@Component({
  selector: 'app-user-problems-orsuggestions',
  templateUrl: './user-problems-orsuggestions.component.html',
  styleUrls: ['./user-problems-orsuggestions.component.css']
})
export class UserProblemsORsuggestionsComponent implements OnInit {
  userProblemOrSuggestions: any;
  fileType: any;
  problemSuggestionId: any;
  subject: any;
  UserEmail: any;
  description: any;
  isSubmited: boolean = false;
  IsTicketStatus: any;
  allUserProblemOrSuggestions: any;
  isclosed: boolean = false;
  Checkrecords: boolean = false;
  isRespondOrClose: boolean;
  respondedDate: any;
  datePipe: any;
  formattedDates: any;
  proborSuggestion:any=[];
  userId:any;

  constructor(private toastr: ToastrService, private router: Router, public userService: UserService,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getAlluserProblemOrSuggestions();
  }

  getAlluserProblemOrSuggestions() {
    this.spinner.show();
    this.userService.GetAllUserProblemorSuggestion().subscribe((data: any) => {
      this.spinner.hide();
      this.allUserProblemOrSuggestions = data.userProblemsSuggestions;
      this.userProblemOrSuggestions = data.userProblemsSuggestions.filter(x => x.isTicketStatus == true);
      this.IsTicketStatus = true;
      if (!this.userProblemOrSuggestions.length) {
        this.Checkrecords = true;
      }
      //this.IsTicketStatus = 
      console.log(data);
    })
  }

  Change(e) {
    if (e.target.value == "true") {
      this.userProblemOrSuggestions = this.allUserProblemOrSuggestions.filter(x => x.isTicketStatus == true);
      this.isclosed = false;
    }
    else {
      this.userProblemOrSuggestions = this.allUserProblemOrSuggestions.filter(x => x.isTicketStatus == false);
      this.isclosed = true;
    }
    if (!this.userProblemOrSuggestions.length) {
      this.Checkrecords = true;
    }
    else {
      this.Checkrecords = false;
    }
  }

  Downloadfile(imgtype: any) {
    if (imgtype != null) {
      let type = [];
      // type = this.albumd.path.concat(this.albumDetails.albumId, '/', this.albumd.albumDetails.thumbImage).split(/[\s.]+/);
      // this.fileType = type[type.length - 1];
      type = imgtype.thumbImage.split(/[\s.]+/);
      this.fileType = type[type.length - 1];
      const linkSource = `data:application/${this.fileType};base64,` + imgtype.thumbImageBytes;
      const downloadLink = document.createElement("a");
      const fileName = "Problems or Suggestions" + "." + this.fileType;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }
  getFileType(url: any): string {
    const parts = url.split('.');
    const extension = parts[parts.length - 1].toLowerCase();
    return extension;
  }

  onEmailOpen(item) {debugger;
    this.problemSuggestionId = item.problemOrSuggestionId;
    this.subject = item.problemOrSuggessionTypeName;
    this.UserEmail = item.email;
    this.userId=item.userId;
    console.log(item)
    //this.respondedDate = item.respondedOn.split(',');
    // if (this.respondedDate && this.respondedDate.length > 0) {
    //   // Format each date
    //   this.formattedDates = this.respondedDate.map(date => {
    //     const parsedDate = new Date(date.trim());

    //     // Check if the date is valid before formatting
    //     if (!isNaN(parsedDate.getTime())) {
    //       return this.datePipe.transform(parsedDate, 'dd MMM yyyy, h:mm a') || '';
    //     } else {
    //       console.warn(`Invalid date encountered: ${date}`);
    //       return 'Invalid date'; // Fallback for invalid dates
    //     }
    //   });

    //   console.log('Formatted Dates:', this.formattedDates);
    // }
    this.userService.GetUserProblemorSuggestionById(this.problemSuggestionId).subscribe((ps:any)=>{console.log(ps);debugger
      this.proborSuggestion=ps.problemSuggestionRespond;  
    })
  }

  respondBack() {
    // this.problemSuggestionId = 0;
    //this.respondMessage = this.message;
    // this.userService.updateProblemSuggestionRespond(this.problemSuggestionId, this.UserEmail, this.subject, this.description).subscribe((res: any) => {
    //   console.log(res);
    //   if (res.isSuccess == true) {
    //     this.toastr.success('Respond Back successfully');
    //   }
    // });
    this.isRespondOrClose = true;
    this.isSubmited = false
    if (this.description == null || this.description == undefined || this.description == "") {
      this.isSubmited = true;
      return;
    }
    else {
      this.isSubmited = false;
      this.spinner.show();
      this.userService.updateProblemSuggestionRespond(this.problemSuggestionId, this.isRespondOrClose, this.UserEmail, this.subject, this.description,this.userId).subscribe((res: any) => {
        console.log(res);
        if (res.isSuccess == true) {
          this.toastr.success('Respond Back successfully');
          this.isSubmited = false;
          this.OnClose();
          this.getAlluserProblemOrSuggestions();
          this.spinner.hide();
        }
        else {
          this.toastr.error(res.message);
          //this.isSubmited = true;
          this.OnClose();
        }
      });
    }
  }

  closeTicket() {debugger;
    this.isRespondOrClose = false;
    this.isSubmited = false
    if (this.description == null || this.description == undefined || this.description == "") {
      this.isSubmited = true;
      return
    }
    else {
      this.isSubmited = false;
      this.spinner.show();
      this.userService.updateProblemSuggestionRespond(this.problemSuggestionId, this.isRespondOrClose, this.UserEmail, this.subject, this.description,this.userId).subscribe((res: any) => {
        // console.log(res);
        if (res.isSuccess == true) {
          this.toastr.success('Respond Back and ticket closed successfully');
          this.isSubmited = false;
          this.OnClose();
          this.getAlluserProblemOrSuggestions();
          this.spinner.hide();
        }
      });
    }
  }

  OnClose() {
    this.description = "";
    this.respondedDate = [];
  }

  OnCancleTicket(item) {
    if (confirm('Please note! Are you sure you want to Close Ticket?')) {
      this.UserEmail = "";
      this.subject = "";
      this.description = "";
      this.isRespondOrClose = false;
      this.userService.updateProblemSuggestionRespond(item.problemOrSuggestionId, this.isRespondOrClose, this.UserEmail, this.subject, this.description,this.userId).subscribe((res: any) => {
        // console.log(res);
        if (res.isSuccess == true) {
          this.toastr.success('Ticket closed successfully');
          this.isSubmited = false;
          this.getAlluserProblemOrSuggestions();
          //this.OnClose();
        }
      });
    }

  }

}
