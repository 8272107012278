import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlbumService } from 'src/app/Shared/album.service';
import { ArtistsService } from 'src/app/Shared/artists.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { ConfigService } from 'src/app/app.config';
import { Location } from '@angular/common';
import { UserHomeSection, Users } from 'src/app/models/users';
import { UserService } from 'src/app/Shared/user.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HomeService } from 'src/app/Shared/home.service';


@Component({
  selector: 'app-add-update-homepagesection',
  templateUrl: './add-update-homepagesection.component.html',
  styleUrls: ['./add-update-homepagesection.component.css']
})



export class AddUpdateHomepagesectionComponent implements OnInit {

  artists: any;
  albums: any;
  songs: any = [];
  imageType: any;
  isArtist: boolean = false;
  isAlbum: boolean = false;
  isSongs: boolean = false;
  isplaylist: boolean = false;
  numbers: any;
  x;
  i = 0;
  userDetails = new Users();
  homeSection = new UserHomeSection();
  imgURL: any;
  fileToUpload: File;
  files: any;
  noImage: boolean = true;
  bannerlist: any;
  timgN: any;
  slectedData: any = []
  dropdownClose = false
  searchValue: string = '';
  enteredValue = ""
  submitted: boolean;
  objType: string = "";
  objTypeArtist: string = "";
  objTypeAlbum: string = "";
  objTypePlaylist: string = "";
  searchTexts: string = "";
  isChecked: boolean = true;
  isRandomize : boolean = false;
  title = "Add"
  //SelectedCoArtistId: any[] = [];

  searchText: string = '';

  dropdowns = [
    {
      searchText: '',
      options: [
        { label: 'Option 1', checked: false },
        { label: 'Option 2', checked: false },
        { label: 'Option 3', checked: false }
      ]
    },
    // Add more dropdowns as needed
  ];

  filteredData = [];
  dropdownList = [];
  selectedItems = [];
  selectedSongs = [];
  selectedAlbums = [];
  dropdownSettings: IDropdownSettings = {};
  songList: any[];
  songDropdownSettings: IDropdownSettings = {};
  albumDropdownSettings: IDropdownSettings = {};
  artistDropDownSettings: IDropdownSettings = {};
  playlistDropDownSettings: IDropdownSettings = {};
  textName: any;
  id: any;
  playListDetails: any;
  sectionList: any;
  allSongs: any[];
  objSkills: any = '';
  selectedArtists = [];
  selectedPlaylists = [];
  edit: boolean = true;
  selectedSongIds: string;
  selectedArtistIds: string;
  selectedAlbumsIds: string;
  selectedPlaylistIds: string;
  allDetails: any = [];
  DetailsExist: boolean = true;
  filteredSongs: any[] = [];
  currentIndex: number = 0;
  batchSize: number = 20;

  constructor(private _songsService: SongsService, private _artistsService: ArtistsService,
    private formBuilder: FormBuilder, private albumService: AlbumService,
    public location: Location,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private configService: ConfigService,
    private userService: UserService,
    private toastr: ToastrService, private route: ActivatedRoute, public _homeService: HomeService) {
    var i = 1
    this.numbers = new Array(25).fill(0).map((x, i) => i + 1);
    if (this.homeSection.HomeSectionId === null || this.homeSection.HomeSectionId === 0 || this.homeSection.HomeSectionId === undefined) {
      debugger;
      this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.getAlbum();
      this.getArtists();
      if (this.userDetails.userId) {
        this.searchTexts = '';
        this.getSongs(this.searchTexts, this.userDetails.userId);
      }
      this.getplaylist();
    }
  }

  ngOnInit() {
    debugger;
    this.homeSection.isActive = this.isChecked;
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    if (this.route.snapshot.paramMap.get("id")) {
      this.homeSection.HomeSectionId = this.route.snapshot.paramMap.get("id");
      this.searchTexts = '';
      this.getSongs(this.searchTexts, this.userDetails.userId);
      this.getAlbum();
      this.getArtists();
      this.getplaylist();
      setTimeout(() => {
        this.getHomesectionById(this.homeSection.HomeSectionId);
        //this.getSongs(this.searchTexts, this.userDetails.userId);
      }, 1000);

    }
    else {
      this.homeSection.HomeSectionId = 0;
    }


    this.songDropdownSettings = {
      singleSelection: false,
      idField: 'songId',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Data Not Found!',

    };

    this.albumDropdownSettings = {
      singleSelection: false,
      idField: 'albumId',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Data Not Found!',
    };
    this.artistDropDownSettings = {
      singleSelection: false,
      idField: 'artistId',
      textField: 'fullName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Data Not Found!',

    };
    this.playlistDropDownSettings = {
      singleSelection: false,
      idField: 'playlistId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 100,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'Data Not Found!',

    };

    this.getAllHomeSection();


  }

  search(e) {
    debugger;
    this.textName = e.target.value

    if (this.textName == null || this.textName == '') {
      this.searchTexts = '';
      this.getSongs(this.searchTexts, this.userDetails.userId);
    }
    else {
      this.searchTexts = this.textName;
      this.getSongs(this.searchTexts, this.userDetails.userId);
    }
  }

  getArtists() {
    this._songsService.getArtists().subscribe((data: any) => {
      this.artists = data.artistDetails;
    });
  }

  getAlbum() {
    this.albumService.getalbum().subscribe((data: any) => {
      this.albums = data.albums;
      console.log(data);
      // if (this.homeSection.HomeSectionId != 0) {
      //   this.selectedAlbums = data.albums
      //     .filter(album => this.homeSection.AlbumId.includes(album.albumId))
      //     .map(album => album.title);
      //   //console.log(this.selectedSongs);
      // }
    });
  }

  getSongs(searchTexts, userId) {
    this._songsService.getSongs(searchTexts, userId).subscribe((data: any) => {
      console.log('song1', data.songs);
      //alert('song1');
      this.songs = data.songs.slice(0,20);
      this.allSongs = data.songs;
      //console.log('song1', data);
    });
    // if (searchTexts != '') {
    //   this._songsService.getSongs(searchTexts, userId).subscribe((data: any) => {
    //     this.songs = data.songs;
    //     this.allSongs = data.songs;
    //     console.log('song1', data);
    //   });
    // }
    // else {
    //   this._songsService.getSongs(searchTexts, userId).subscribe((data: any) => {
    //     this.allSongs = data.songs;
    //     this.songs = data.songs.slice(0, 50);
    //     console.log('song2', this.allSongs, this.songs);
    //   });
    // }
  }

  selectType(e: any) {
    debugger;
    this.imageType = e.target.value;
    console.log(this.imageType);
    if (this.imageType == "1") {
      this.getArtists();
      this.isArtist = true;
      this.isAlbum = false;
      this.isSongs = false;
      this.isplaylist = false;
    }
    if (this.imageType == "2") {
      this.getAlbum();
      this.isAlbum = true;
      this.isSongs = false;
      this.isplaylist = false;
      this.isArtist = false;
    }
    if (this.imageType == "3") {
      //this.getlocalsongdb();

      // this.searchTexts = '';
      // this.getSongs(this.searchTexts, this.userDetails.userId);
      this.isSongs = true;
      this.isplaylist = false;
      this.isArtist = false;
      this.isAlbum = false;

    }
    if (this.imageType == "4") {
      this.getplaylist();
      this.isplaylist = true;
      this.isArtist = false;
      this.isAlbum = false;
      this.isSongs = false;
    }
    this.clearForm();
  }

  back() {
    this.location.back();
  }

  onSubmit() {
    debugger;
    if (this.selectedArtistIds != "" && this.selectedArtistIds != undefined) {
      this.homeSection.ArtistId = this.selectedArtistIds;
    }
    else {
      this.homeSection.ArtistId = null;
    }
    if (this.selectedAlbumsIds != "" && this.selectedAlbumsIds != undefined) {
      this.homeSection.AlbumId = this.selectedAlbumsIds;
    }
    else {
      this.homeSection.AlbumId = null;
    }
    if (this.selectedSongIds != "" && this.selectedSongIds != undefined) {
      this.homeSection.SongId = this.selectedSongIds;
    }
    else {
      this.homeSection.SongId = null;
    }
    if (this.selectedPlaylistIds != "" && this.selectedPlaylistIds != undefined) {
      this.homeSection.PlaylistId = this.selectedPlaylistIds;
    }
    else {
      this.homeSection.PlaylistId = null;
    }

    // this.homeSection.isActive = this.isChecked;
    console.log(this.homeSection);
    const IsExist = this.isFaqExist(this.homeSection.OrderSection, this.homeSection.SectiontypeId);

    if (IsExist) {
      return false;

    }

    this.userService.addupdatehomeSection(this.homeSection).subscribe((data: any) => {
      debugger;
      console.log(data);
      if (data.status == 200) {
        this.toastr.success(data.message);
        this.spinner.hide();
        // if (data.homeSection.homeSectionId != 0) {

        // } else {
        //   this.toastr.success("Section added successfully");
        //   this.spinner.hide();
        // }
        this.location.back();
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }


  customSearchFn(term: string, item) {
    item.name = item.name.replace(',', '');
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }


  onItemSelect(item: any, type: any) {
    debugger;
    if (type == "Artist") {
      const validSelectedItems = this.selectedArtists
        .filter(item => item.artistId);
      const selectedItemsString = validSelectedItems.map(item => item.artistId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedArtistIds = selectedItemsString;
    }
    else if (type == "Album") {
      const validSelectedItems = this.selectedAlbums
        .filter(item => item.albumId);
      const selectedItemsString = validSelectedItems.map(item => item.albumId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedAlbumsIds = selectedItemsString;
    }
    else if (type == "Songs") {
      const validSelectedItems = this.selectedSongs
        .filter(item => item.songId);
      const selectedItemsString = validSelectedItems.map(item => item.songId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedSongIds = selectedItemsString;
    }
    else {
      const validSelectedItems = this.selectedPlaylists
        .filter(item => item.playlistId);
      const selectedItemsString = validSelectedItems.map(item => item.playlistId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedPlaylistIds = selectedItemsString;

    }

  }

  onItemDeSelect(item: any, type: any) {
    debugger;
    if (type == "Artist") {
      const validSelectedItems = this.selectedArtists
        .filter(item => item.artistId);
      const selectedItemsString = validSelectedItems.map(item => item.artistId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedArtistIds = selectedItemsString;
    }
    else if (type == "Album") {
      const validSelectedItems = this.selectedAlbums
        .filter(item => item.albumId);
      const selectedItemsString = validSelectedItems.map(item => item.albumId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedAlbumsIds = selectedItemsString;
    }
    else if (type == "Songs") {
      const validSelectedItems = this.selectedSongs
        .filter(item => item.songId);
      const selectedItemsString = validSelectedItems.map(item => item.songId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedSongIds = selectedItemsString;
    }
    else {
      const validSelectedItems = this.selectedPlaylists
        .filter(item => item.playlistId);
      const selectedItemsString = validSelectedItems.map(item => item.playlistId).join(',');
      var obj = ""
      var data = obj + selectedItemsString;
      this.objType = data;
      this.selectedPlaylistIds = selectedItemsString;
    }
  }

  toggleDropdown(dropdownId) {
    var dropdown = document.getElementById(dropdownId);
    dropdown.style.display = (dropdown.style.display === 'block') ? 'none' : 'block';
  }

  getplaylist() {
    this._homeService.getadminPlaylist().subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      // if (this.homeSection.HomeSectionId != 0) {
      //   this.selectedPlaylists = data.playListDetails
      //     .filter(playlist => this.homeSection.PlaylistId.includes(playlist.playlistId))
      //     .map(playlist => playlist.name);
      //   //console.log(this.selectedSongs);
      // }
      console.log('playlist', this.playListDetails);
    })
  }

  Checked(value) {
    this.homeSection.isActive = value;
  }

  getHomesectionById(homeSectionId) {
    debugger;
    this.userService.getHomeSectionById(homeSectionId).subscribe((data: any) => {
      this.edit = false;
      this.title = "Edit";
      this.sectionList = data.homeSection[0];
      this.homeSection.SectionTitle = this.sectionList.sectionTitle;
      this.homeSection.OrderSection = this.sectionList.orderSection;
      this.isChecked = this.sectionList.isActive;
      this.isRandomize = this.sectionList.isRandomize;
      if (this.sectionList.artistId != null) {
        this.homeSection.SectiontypeId = 1;
        var artistIds = this.sectionList.artistId.split(',').map(Number);
        artistIds.forEach(artistid => {
          var artistobj = this.artists.find(x => x.artistId === artistid);
          var obj = {
            "artistId": artistobj.artistId,
            "fullName": artistobj.fullName
          }
          this.selectedArtists.push(obj);
        });
        const validSelectedItems = this.selectedArtists
          .filter(item => item.artistId);
        const selectedItemsString = validSelectedItems.map(item => item.artistId).join(',');
        var obj = ""
        var str = obj + selectedItemsString;
        this.objType = str;
        this.selectedArtistIds = selectedItemsString;
        this.isArtist = true;
        this.isAlbum = false;
        this.isSongs = false;
        this.isplaylist = false;
      }
      if (this.sectionList.albumId != null) {
        this.homeSection.SectiontypeId = 2;
        var albumIds = this.sectionList.albumId.split(',').map(Number);
        albumIds.forEach(albumid => {
          var albumobj = this.albums.find(x => x.albumId === albumid);
          var obj = {
            "albumId": albumobj.albumId,
            "title": albumobj.title
          }
          this.selectedAlbums.push(obj);
        });
        const validSelectedItems = this.selectedAlbums
          .filter(item => item.albumId);
        const selectedItemsString = validSelectedItems.map(item => item.albumId).join(',');
        var obj = ""
        var srj = obj + selectedItemsString;
        this.objType = srj;
        this.selectedAlbumsIds = selectedItemsString;
        //console.log(this.homeSection.AlbumId.map(id => this.albums.find(song => song.albumId === id)));
        this.isArtist = false;
        this.isAlbum = true;
        this.isSongs = false;
        this.isplaylist = false;
      }
      if (this.sectionList.songId != null) {
        this.homeSection.SectiontypeId = 3;
        this.getSongs(this.searchTexts, this.userDetails.userId);
        this.homeSection.SongId = this.sectionList.songId.split(',').map(Number);
        if (this.allSongs && Array.isArray(this.allSongs)) {
          var songIds = this.sectionList.songId.split(',').map(Number);
          songIds.forEach(songid => {
            const songobj = this.allSongs.find(x => x.songId === songid);
            if (songobj) {  // Check if songobj is found
              const obj = {
                songId: songobj.songId,
                title: songobj.title
              };
              this.selectedSongs.push(obj);
              console.log('selected' , this.selectedSongs);
            }
          });
        }

        // songIds.forEach(songid => {
        //   var songobj = this.allSongs.find(x => x.songId === songid);
        //   var obj = {
        //     "songId": songobj.songId,
        //     "title": songobj.title
        //   }
        //   this.selectedSongs.push(obj);
        // });
        this.isSongs = true;
        const validSelectedItems = this.selectedSongs
          .filter(item => item.songId);
        const selectedItemsString = validSelectedItems.map(item => item.songId).join(',');
        var obj = ""
        var str = obj + selectedItemsString;
        this.objType = str;
        this.selectedSongIds = selectedItemsString;
        this.isArtist = false;
        this.isAlbum = false;
        this.isplaylist = false;
      }
      if (this.sectionList.playlistId != null) {
        this.homeSection.SectiontypeId = 4;
        var playlistIds = this.sectionList.playlistId.split(',').map(Number);
        playlistIds.forEach(playlistid => {
          var playlistobj = this.playListDetails.find(x => x.playlistId === playlistid);
          var obj = {
            "playlistId": playlistobj.playlistId,
            "name": playlistobj.name
          }
          this.selectedPlaylists.push(obj);
        });
        const validSelectedItems = this.selectedPlaylists
          .filter(item => item.playlistId);
        const selectedItemsString = validSelectedItems.map(item => item.playlistId).join(',');
        var obj = ""
        var srx = obj + selectedItemsString;
        this.objType = srx;
        this.selectedPlaylistIds = selectedItemsString;
        this.isArtist = false;
        this.isAlbum = false;
        this.isSongs = false;
        this.isplaylist = true;
      }
    });

  }

  getAllHomeSection() {
    this.userService.getAllHomeSection().subscribe((data: any) => {
      this.allDetails = data.homeSectionobj
      console.log(this.allDetails)
    });
  }

  isFaqExist(orderSection: any, sectionType: any) {
    debugger


    let exists = this.allDetails.find(
      faq => faq.orderSection == orderSection
    ) !== undefined;
    if (this.homeSection.HomeSectionId > 0) {
      exists = this.allDetails.find(
        faq => faq.orderSection == orderSection && faq.homeSectionId != this.homeSection.HomeSectionId
      ) !== undefined;

    }

    if (exists) {
      this.toastr.error("Order Already Exist.");
      this.DetailsExist = true;
      return true;
    }

    return false;
  }

  clearForm() {
    this.selectedArtists = null;
    this.selectedAlbums = null;
    this.selectedSongs = null;
    this.playListDetails = null;
    this.selectedArtistIds = null;
    this.selectedAlbumsIds = null;
    this.selectedSongIds = null;
    this.selectedPlaylistIds = null;
    this.homeSection.AlbumId = null;
    this.homeSection.ArtistId = null;
    this.homeSection.SongId = null;
    this.homeSection.PlaylistId = null;
  }

  Randomize(value){
    this.homeSection.isRandomize = value;
  }

}
