import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../app.config';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
declare const FB: any;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = '';
  private baseUrl = '';
  public searchText = '';
  public theme1 = true;
  favdjmix: any = '';
  public playId = [];
  public content = new BehaviorSubject<any>(this.favdjmix);
  public share = this.content.asObservable();
  private displaySupportPage = new BehaviorSubject<boolean>(false);
  displaySupport = this.displaySupportPage.asObservable();
  private Isrouting = new Subject<boolean>();
  private Isuser = new Subject<boolean>();
  private notifySupportPage = new BehaviorSubject<boolean>(false);
  notifySupport = this.notifySupportPage.asObservable();
  // private IsSongPage = new Subject<{ isSongPage: boolean; songId: number }>();
  constructor(private http: HttpClient, private configService: ConfigService) {
    this.baseUrl = configService.getServiceBaseUrl();
    //this.loadFacebookSDK();
    // var isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    // if(isLoggedIn === true){
    //   this.IsUser(isLoggedIn);
    //   this.Isuser.next(true);
    // }  
  }


  IsRouting(message: boolean) {
    this.Isrouting.next(message);
  }

  getIsRouting(): Observable<any> {
    return this.Isrouting.asObservable();
  }


  IsUser(message: boolean) {
    this.Isuser.next(message);
  }

  getIsUser(): Observable<any> {
    return this.Isuser.asObservable();
  }
  // IssongPages(data: { isSongPage: boolean; songId: number }) {
  //   this.IsSongPage.next(data);
  // }

  // getIssongPage(): Observable<any> {
  //   return this.IsSongPage.asObservable();
  // }

  getLatestPlaylist(data) {
    this.content.next(data);
    this.playId = data;
  }
  getUserDetails(columName, sortedOrder, searchName, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Users/GetAllUsers?ColumName=' + columName + '&SortedOrder=' + sortedOrder + '&SearchName=' + searchName + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }
  deleteUser(userId): Observable<any> {
    this.url = this.baseUrl + 'api/Users/DeleteUser?UserId=' + userId;
    return this.http.post(this.url, userId)
  }

  GetUserDetails(UserId) {
    this.url = this.baseUrl + 'api/Users/GetUserDetails?UserId=' + UserId;
    return this.http.get(this.url);
  }
  enabledisableuser(UserId, flag) {
    this.url = this.baseUrl + 'api/Users/EnableDisableUser?UserId=' + UserId + '&flag=' + flag;
    return this.http.post(this.url, UserId)
  }

  GetThemes() {
    this.url = this.baseUrl + 'api/Settings/GetThemes';
    return this.http.get(this.url);
  }

  ChangeTheme(UserId, ThemeId) {
    this.url = this.baseUrl + 'api/Settings/ChangeTheme?UserId=' + UserId + '&ThemeId=' + ThemeId;
    return this.http.post(this.url, UserId)
  }

  GetContentByCode(Code) {
    this.url = this.baseUrl + 'api/PageContent/GetContentByCode?Code=' + Code;
    return this.http.get(this.url);
  }

  AddUpdateContent(pagecontent) {
    this.url = this.baseUrl + 'api/PageContent/AddUpdateContent';
    return this.http.post(this.url, pagecontent);
  }

  GetAllContents() {
    this.url = this.baseUrl + 'api/PageContent/GetAllContent';
    return this.http.get(this.url);
  }

  Getallsugestion(columName, sortedOrder, searchName, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Users/GetAllSuggetions?ColumName=' + columName + '&SortedOrder=' + sortedOrder + '&SearchName=' + searchName + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }

  GetsugestionById(SuggetionId) {
    this.url = this.baseUrl + 'api/Users/GetsuggestionById?SuggetionId=' + SuggetionId;
    return this.http.get(this.url);
  }

  addupdatesuggestion(data) {
    this.url = this.baseUrl + 'api/Users/AddSuggestion';
    return this.http.post(this.url, data)
  }

  Getallproblem(columName, sortedOrder, searchName, pageIndex, pageSize) {
    this.url = this.baseUrl + 'api/Users/GetAllProblems?ColumName=' + columName + '&SortedOrder=' + sortedOrder + '&SearchName=' + searchName + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize;
    return this.http.get(this.url);
  }

  GetproblemById(ProblemId) {
    this.url = this.baseUrl + 'api/Users/GetProblemById?ProblemId=' + ProblemId;
    return this.http.get(this.url);
  }

  addupdateproblem(data) {
    this.url = this.baseUrl + 'api/Users/AddProblem';
    return this.http.post(this.url, data)
  }

  addtermsand_condition(id, isAccept) {
    this.url = this.baseUrl + 'api/Artists/UpdateTCForArtist?ArtistId=' + id + '&Accept=' + isAccept;
    return this.http.post(this.url, null)
  }

  UpdateProfileType(id, ProfileType) {
    this.url = this.baseUrl + 'api/Users/UpdateProfileType?UserId=' + id + '&ProfileType=' + ProfileType;
    return this.http.post(this.url, null)
  }

  InviteUser(InviteUsers) {
    this.url = this.baseUrl + 'api/Users/InviteUser?userId=' + InviteUsers.userId + '&fullName=' + InviteUsers.fullName + '&email=' + InviteUsers.email + '&status=' + InviteUsers.status;
    return this.http.post(this.url, null)
  }

  GetInviteUsers(userId) {
    this.url = this.baseUrl + 'api/Users/GetInviteUsers?userId=' + userId;
    return this.http.get(this.url);
  }

  DeleteInvitedUser(invtId, email, stats) {
    this.url = this.baseUrl + 'api/Users/DeleteInvitedUser?invitedId=' + invtId + '&invitedEmail=' + email + '&invitedStatus=' + stats;
    return this.http.get(this.url);
  }
  GetAllAdminUsers() {
    this.url = this.baseUrl + 'api/Artists/GetAllAdminUsers'
    return this.http.get(this.url);
  }

  addupdatehomeBanner(data) {
    this.url = this.baseUrl + 'api/Users/AddUpdateHomeBanner';
    return this.http.post(this.url, data)
  }

  getAllBannerDetails() {
    this.url = this.baseUrl + 'api/Users/GetAllBannerDetails'
    return this.http.get(this.url);
  }

  getBannerDetailsById(homeBannerId) {
    this.url = this.baseUrl + 'api/Users/GetBannerDetailsById?homeBannerId=' + homeBannerId;
    return this.http.get(this.url);
  }

  addupdatehomeSection(data) {
    this.url = this.baseUrl + 'api/Users/AddUpdateHomeSection';
    return this.http.post(this.url, data);
  }

  getAllHomeSection() {
    this.url = this.baseUrl + 'api/Users/GetAllHomeSections';
    return this.http.get(this.url);
  }

  deleteSection(homeSectionId): Observable<any> {
    this.url = this.baseUrl + 'api/Users/DeleteSection?HomeSectionId=' + homeSectionId;
    return this.http.post(this.url, homeSectionId)
  }

  getHomeSectionById(homeSectionId) {
    this.url = this.baseUrl + 'api/Users/GetHomeSectionById?homeSectionId=' + homeSectionId;
    return this.http.get(this.url);
  }

  deleteProfileImage(userId) {
    this.url = this.baseUrl + 'api/Users/DeleteProfileImage?UserId=' + userId;
    return this.http.post(this.url, userId)
  }

  GetAllAdminNotification() {
    this.url = this.baseUrl + 'api/Users/GetAllAdminNotification'
    return this.http.get(this.url);
  }

  AddUpdateAdmNotification(data) {
    this.url = this.baseUrl + 'api/Users/AddUpdateAdmNotification';
    return this.http.post(this.url, data)
  }

  deleteHomeBanner(homebannerId): Observable<any> {
    this.url = this.baseUrl + 'api/Users/DeleteHomeBanner?HomebannerId=' + homebannerId;
    return this.http.post(this.url, homebannerId)
  }
  AddUpdateUserAddress(User) {
    this.url = this.baseUrl + 'api/Users/AddUpdateAddress';
    return this.http.post(this.url, User)
  }
  UpdateEmailId(userId, emailId) {
    this.url = this.baseUrl + 'api/Users/UpdateEmailId?UserId=' + userId + '&EmailId=' + emailId;
    return this.http.get(this.url)
  }

  GetMemberDetailsbyId(parentId) {
    this.url = this.baseUrl + 'api/Users/GetMemberDetailsbyId?parentId=' + parentId;
    return this.http.get(this.url);
  }

  GetAllProblemorSuggestionTypes() {
    this.url = this.baseUrl + 'api/Users/GetAllProblemsSuggestionsTypes';
    return this.http.get(this.url)
  }

  addProblemOrSuggestion(data) {
    this.url = this.baseUrl + 'api/Users/AddUserProblemOrSuggestions';
    return this.http.post(this.url, data)
  }

  GetAllUserProblemorSuggestion() {
    this.url = this.baseUrl + 'api/Users/GetAllUserProblemsOrSuggestions';
    return this.http.get(this.url)
  }
  getNotificationById(notificationId) {
    this.url = this.baseUrl + 'api/Users/GetNotificationById?NotificationId=' + notificationId;
    return this.http.get(this.url)
  }

  UserNotificationSettings(data) {
    this.url = this.baseUrl + 'api/Users/UserNotificationSettings';
    return this.http.post(this.url, data)
  }

  getAllUserNotificationSettings() {
    this.url = this.baseUrl + 'api/Users/GetAllUserNotificationSettings';
    return this.http.get(this.url)
  }

  sendMail(data) {
    debugger;
    this.url = this.baseUrl + 'api/Users/SendUserEmailNotifications';
    return this.http.post(this.url, data)
  }
  showSupportPage(show: boolean) {
    this.displaySupportPage.next(show);
  }

  shownotifyPage(show: boolean) {
    this.notifySupportPage.next(show);
  }
  updateProblemSuggestionRespond(problemSuggestionId, isRespondOrClose, email, subject, respondMessage, userId) {
    this.url = this.baseUrl + 'api/Users/UpdateProblemSuggestionRespond?problemSuggestionId=' + problemSuggestionId + '&isRespondOrClose=' + isRespondOrClose + '&email=' + email + '&subject=' + subject + '&respondMessage=' + respondMessage + '&UserId=' + userId;
    return this.http.post(this.url, problemSuggestionId)
  }

  checkDisplayName(displayName): Observable<any> {
    this.url = this.baseUrl + 'api/Users/CheckDisplayNameSimilar?DisplayName=' + displayName;
    return this.http.get(this.url)
  }

  SendPushNotification(notificationId, tittle, descriptions) {
    this.url = this.baseUrl + 'api/Users/SendUserPushNotifications?NotificationId=' + notificationId + '&tittle=' + tittle + '&descriptions=' + descriptions;
    return this.http.get(this.url)

  }
  // private loadFacebookSDK() {
  //   (window as any).fbAsyncInit = () => {
  //     FB.init({
  //       appId: '453277467562700',
  //       cookie: true,
  //       xfbml: true,
  //       version: 'v10.0'
  //     });
  //     console.log('Facebook SDK initialized');
  //   };

  //   ((d, s, id) => {
  //     let js, fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) { return; }
  //     js = d.createElement(s); js.id = id;
  //     js.src = 'https://connect.facebook.net/en_US/sdk.js';
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, 'script', 'facebook-jssdk');
  // }

  // shareViaMessenger(link: string) {
  //   FB.ui({
  //     method: 'send',
  //     link: link,
  //   }, (response) => {
  //     console.log('FB.ui response:', response);
  //     if (response && !response.error_message) {
  //       console.log('Posting completed.');
  //     } else {
  //       console.error('Error while posting:', response ? response.error_message : 'No response');
  //       alert('An error occurred. Please try later.');
  //     }
  //   });
  // }

  // getFacebookShareUrl(link: string): string {
  //   // const appId = '453277467562700'; // Replace with your actual Facebook App ID
  //   // const fbDialogUrl = `https://www.facebook.com/dialog/send?app_id=${appId}&link=${encodeURIComponent(link)}&redirect_uri=${encodeURIComponent(window.location.href)}`;
  //   // return fbDialogUrl;
  //   const appId = '1026377378901392'; // Replace with your actual Facebook App ID
  //   const fbDialogUrl = `https://www.facebook.com/dialog/send?app_id=${appId}&link=${encodeURIComponent(link)}&redirect_uri=${encodeURIComponent(window.location.href)}`;
  //   return fbDialogUrl;
  // }
  GetUserProblemorSuggestionById(ProblemorSuggestionId) {
    this.url = this.baseUrl + 'api/Users/GetUserProblemsOrSuggestionsById?ProblemOrSuggestionId=' + ProblemorSuggestionId;
    return this.http.get(this.url)
  }

  GetProbSuggstByUserId(userId) {
    this.url = this.baseUrl + 'api/Users/GetProbSuggstReplyUserId?userId=' + userId;
    return this.http.get(this.url)
  }
  UpdateUserResponseText(ProblemSuggestionResponsesId, UserId, userReplytexts) {
    this.url = this.baseUrl + 'api/Users/UpdateUserResponseText?ProblemSuggestionResponsesId=' + ProblemSuggestionResponsesId + '&UserId=' + UserId + '&userResponseTexts=' + userReplytexts;
    return this.http.get(this.url)
  }
}
