import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Users } from 'src/app/models/users';
import { PlayerService } from 'src/app/Shared/player.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { HomeService } from 'src/app/Shared/home.service';

@Component({
  selector: 'app-topfav-songspage',
  templateUrl: './topfav-songspage.component.html',
  styleUrls: ['./topfav-songspage.component.css']
})
export class TopfavSongspageComponent implements OnInit {

  userDetails = new Users();
  title: any;
  tfavsongs: any;
  IsActiveSongId: any;
  tfavlength: any;
  top10favSongs: any;
  nextallfavSongs: any;
  topNewArtists: any;
  nextallNewArtists: any;
  //topNewArtistLength:any;
  albumDetails: any;
  topNewAlbums: any;
  nextallNewAlbums: any;
  // topNewAlbumsLength:any;
  UserId: any;
  id: any;
  artistDetails: any[] = [];
  isFanfrency: boolean = false;
  isloader: boolean = false;
  isplayed: boolean = false;

  constructor(private _songservice: SongsService, private router: Router, private route: ActivatedRoute,
    private loadScript: LoadScriptService, public playerService: AudioPlayerService,
    private _playerService: PlayerService, private homeService: HomeService) {
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value;
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.loadScript.getServiceBaseUrl();
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id == 1) {
      this.gettopfavsong();
      this.title = "Heartbeat Hits";
    }
    else if (this.id == 2) {
      this.getBuzzSongs();
      this.title = "Buzz Beats";
    }
    else if (this.id == 3) {
      this.tfavlength = 10;
      this.isFanfrency = true;
      this.title = "Fan Frenzy";
      this.top10favSongs = this.items;
    }
    //this.getTopNewArtistAlbumSongs();
  }

  Artistprofile(ArtistId: number) {
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }
  gettopfavsong() {
    this.isloader = true;
    this._songservice.GetTopFavSongs(this.userDetails.userId).subscribe((data: any) => {
      this.isloader = false;
      this.tfavsongs = data.getTopFavSongs;
      this.top10favSongs = data.getTopFavSongs.slice(0, 10);
      this.nextallfavSongs = data.getTopFavSongs.slice(10, 50);
      this.tfavlength = data.getTopFavSongs.length;
    });
  }
  getBuzzSongs() {
    this.isloader = true;
    this._songservice.Getlastmonthtopmostplayedsongs(this.UserId).subscribe((data: any) => {
      this.isloader = false;
      //console.log('Buzzsongs', data.lastMonthPlayedSong);
      this.tfavsongs = data.lastMonthPlayedSong;
      this.top10favSongs = data.lastMonthPlayedSong.slice(0, 10);
      this.nextallfavSongs = data.lastMonthPlayedSong.slice(10, 50);
      this.tfavlength = data.lastMonthPlayedSong.length;
    })
  }
  // getTopNewArtistAlbumSongs() {
  //   this.homeService.getTopNewArtistAlbumSongs(this.UserId).subscribe((data: any) => {
  //     debugger
  //     console.log(data)
  //     if (this.id == 3) {
  //       this.title = "Artists";
  //       this.artistDetails = data.newArtAlbSngs.artists;
  //       this.topNewArtists = data.newArtAlbSngs.artists.slice(0, 10);
  //       this.nextallNewArtists = data.newArtAlbSngs.artists.slice(10, 50);
  //       this.tfavlength = data.newArtAlbSngs.artists.length;

  //     }
  //     else if (this.id == 4) {
  //       this.title = "Albums";
  //       this.albumDetails = data.newArtAlbSngs.albums;
  //       this.topNewAlbums = data.newArtAlbSngs.albums.slice(0, 10);
  //       this.nextallNewAlbums = data.newArtAlbSngs.albums.slice(10, 50);
  //       this.tfavlength = data.newArtAlbSngs.albums.length;
  //     }
  //     else if (this.id == 5) {
  //       this.title = "Songs";
  //       this.tfavsongs = data.newArtAlbSngs.songs;
  //       this.top10favSongs = data.newArtAlbSngs.songs.slice(0, 10);
  //       this.nextallfavSongs = data.newArtAlbSngs.songs.slice(10, 50);
  //       this.tfavlength = data.newArtAlbSngs.songs.length;
  //     }
  //   })
  // }
  CheckSubscription(song, i) {
    debugger;
    if (song.length > 0) {
      this.playSong(song, i);
    }
    else {
      this.playSong(song, i);

    }
  }

  playSong(songs, index) {
    debugger;
    let currentIndex = songs.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (currentIndex === index && this.isplayed) {
      this.isplayed = false;
      this.playerService.playSongCallback(this.isplayed);
      return;
    }
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.isplayed = true;
    this.playerService.playSongCallback(this.isplayed);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  items = [
    { id: 1, title: 'Top 50', subtitle: 'Global', category: 'Top 50 Global', backgroundColor: 'linear-gradient(0deg, #8a2386 0%, #8a2386 50%, #ffffff 100%)' },
    { id: 2, title: 'Top 50', subtitle: 'Sweden', category: 'Top 50 Sweden', backgroundColor: 'linear-gradient(0deg, #e2af80 0%, #e2af80 50%, #ffffff 100%)' },
    { id: 3, title: 'Top 50', subtitle: 'Germany', category: 'Top 50 Germany', backgroundColor: 'linear-gradient(0deg, #dacc8a 0%, #dacc8a 50%, #ffffff 100%)' },
    { id: 4, title: 'Top 50', subtitle: 'West', category: 'Top 50 West', backgroundColor: 'linear-gradient(0deg, #e9d0e6 0%, #e9d0e6 50%, #ffffff 100%)' },
    { id: 5, title: 'Top 50', subtitle: 'East', category: 'Top 50 East', backgroundColor: 'linear-gradient(0deg, #bcbff9 0%, #bcbff9 50%, #ffffff 100%)' },
    { id: 6, title: 'Top 50', subtitle: 'Gen Z', category: 'Top 50 Gen Z', backgroundColor: 'linear-gradient(0deg, #b2d5f7 0%, #b2d5f7 50%, #ffffff 100%)' },
    { id: 7, title: 'Top 25', subtitle: 'East Classics', category: 'Top 25 East Classics', backgroundColor: 'linear-gradient(0deg, #bcb4fe 0%, #bcb4fe 50%, #ffffff 100%)' },
    { id: 8, title: 'Top 10', subtitle: 'Playlists', category: 'Top 10 Playlists', backgroundColor: 'linear-gradient(0deg, #cde592 0%, #cde592 50%, #ffffff 100%)' },
    { id: 9, title: 'Top 25', subtitle: 'Church music', category: 'Top 25 Church music', backgroundColor: 'linear-gradient(0deg, #eed4a6 0%, #eed4a6 50%, #ffffff 100%)' },
    { id: 10, title: 'Top 25', subtitle: 'West Classics', category: 'Top 25 West Classics', backgroundColor: 'linear-gradient(0deg, #edb6e0 0%, #edb6e0 50%, #ffffff 100%)' }
  ];

  onClick(e) {
    this.router.navigate([`/topmostsongs/` + e + ``]);
    //this.router.navigate(['topmostsongs'], { queryParams: { id: e } });
  }

}
