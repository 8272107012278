import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SongsService } from '../Shared/songs.service';
import { Users } from '../models/users';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { Location } from '@angular/common';

@Component({
  selector: 'app-topmostsongs',
  templateUrl: './topmostsongs.component.html',
  styleUrls: ['./topmostsongs.component.css']
})
export class TopmostsongsComponent implements OnInit {
  id: any;
  title: string;
  subtitle: string;
  lngcode: string;
  countryId:number=0;
  categoryCode: string;
  songData: any;
  totalcount: any;
  userDetails = new Users();
  UserId: any;
  userFullname: any;
  userImage: any;
  backgroundColor: string;
  IsActiveSongId: any;
  showReleasedOn: boolean = false;
  isSongplay: boolean = false;
  @ViewChild('audioPlayer') player: ElementRef;
  fetchCount: number;
  currentIndex = 0;
  isLoading: boolean;
  isplayed: boolean = false;


  constructor(private route: ActivatedRoute, private spinner: NgxSpinnerService, private router: Router,
    private _songservice: SongsService, private _playerService: PlayerService,
    public playerService: AudioPlayerService, private location: Location) {
      this.playerService.registerPlaySongCallbackno((song) => {
        console.log(song)
        console.log('playingchk', song)
        if (song == true) {
          this.isSongplay = true;
        }
        else {
          this.isSongplay = false;
        }
      });

    // this.id = this.route.snapshot.paramMap.get("id");
    // this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    // this.UserId = this.userDetails["userId"];
    // if (this.id == 1) {
    //   this.title = "Top 50";
    //   this.subtitle = "Global";
    //   this.backgroundColor = 'linear-gradient(0deg, #8a2386 0%, #8a2386 50%, #ffffff 100%)';
    //   this.getmostplayedSongs(this.UserId);
    // }
    // else if (this.id == 2) {
    //   this.title = "Top 50";
    //   this.subtitle = "Sweden";
    //   this.backgroundColor = 'linear-gradient(0deg, #e2af80 0%, #e2af80 50%, #ffffff 100%)';
    //   this.lngcode = "SW"
    //   this.getSongsbylanguageCode(this.lngcode,this.UserId);
    // }
    // else if (this.id == 3) {
    //   this.title = "Top 50";
    //   this.subtitle = "Germany";
    //   this.backgroundColor = 'linear-gradient(0deg, #dacc8a 0%, #dacc8a 50%, #ffffff 100%)';
    //   this.lngcode = "GE"
    //   this.getSongsbylanguageCode(this.lngcode,this.UserId);
    // }
    // else if (this.id == 4) {
    //   this.title = "Top 50";
    //   this.subtitle = "West";
    //   this.backgroundColor = 'linear-gradient(0deg, #e9d0e6 0%, #e9d0e6 50%, #ffffff 100%)';
    //   this.lngcode = "WD"
    //   this.getSongsbylanguageCode(this.lngcode,this.UserId);
    // }
    // else if (this.id == 5) {
    //   this.title = "Top 50";
    //   this.subtitle = "East";
    //   this.backgroundColor = 'linear-gradient(0deg, #bcbff9 0%, #bcbff9 50%, #ffffff 100%)';
    //   this.lngcode = "ED"
    //   this.getSongsbylanguageCode(this.lngcode,this.UserId);
    // }
    // else if (this.id == 6) {
    //   this.title = "Top 50";
    //   this.subtitle = "Gen Z";
    //   this.showReleasedOn=true;
    //   this.backgroundColor = 'linear-gradient(0deg, #b2d5f7 0%, #b2d5f7 50%, #ffffff 100%)';
    //   this.getMostPlayedSongsByUserBornYear(this.UserId );
    // }
    // else if (this.id == 7) {
    //   this.title = "Top 25";
    //   this.subtitle = "East Classics";
    //   this.showReleasedOn=true;
    //   this.backgroundColor ='linear-gradient(0deg, #bcb4fe 0%, #bcb4fe 50%, #ffffff 100%)';
    //   this.lngcode="ED"
    //   this.getMostPlayedSongsByLangAndYear(this.lngcode,this.UserId);
    // }
    // else if (this.id == 8) {
    //   this.title = "Top 10";
    //   this.subtitle = "Playlists";
    //   this.backgroundColor ='linear-gradient(0deg, #cde592 0%, #cde592 50%, #ffffff 100%)';
    //   this.getMostPlayedPlaylist();
    // }
    // else if (this.id == 9) {
    //   this.title = "Top 25";
    //   this.subtitle = "Church music";
    //   this.backgroundColor ='linear-gradient(0deg, #eed4a6 0%, #eed4a6 50%, #ffffff 100%)';
    //   this.categoryCode="ch"
    //   this.getMostPlayedSongsByCategory(this.categoryCode,this.UserId);
    // }
    // else if (this.id == 10) {
    //   this.title = "Top 25";
    //   this.subtitle = "West Classics";
    //   this.showReleasedOn=true;
    //   this.backgroundColor ='linear-gradient(0deg, #edb6e0 0%, #edb6e0 50%, #ffffff 100%)';
    //   this.lngcode="WD";
    //   this.getMostPlayedSongsByLangAndYear(this.lngcode,this.UserId);
    // }
    // this.playerService.getIsblogorCountryScreen().subscribe(value => {
    //   //console.info('san: ' + value);
    //   this.IsActiveSongId = value;console.log(value)
    //   //this.getMostPlayedSongs(this.userDetails.userId);
    // });
  }

  ngOnInit() {debugger
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.id = this.route.snapshot.paramMap.get("id");
    this.IsActiveSongId=Number(localStorage.getItem('CurrentSongId'));
    if (this.id == 1) {
      this.title = "Top 50";
      this.subtitle = "Global";
      this.backgroundColor = 'linear-gradient(0deg, #8a2386 0%, #8a2386 50%, #ffffff 100%)';
      this.getmostplayedSongs();
    }
    else if (this.id == 2) {
      this.title = "Top 50";
      this.subtitle = "Sweden";
      this.backgroundColor = 'linear-gradient(0deg, #e2af80 0%, #e2af80 50%, #ffffff 100%)';
      // this.lngcode = "SW";
      this.countryId = 169;
      this.getSongsbyuserCountry(this.countryId, this.UserId);
    }
    else if (this.id == 3) {
      this.title = "Top 50";
      this.subtitle = "Germany";
      this.backgroundColor = 'linear-gradient(0deg, #dacc8a 0%, #dacc8a 50%, #ffffff 100%)';
      // this.lngcode = "GE"
      this.countryId = 65;
      this.getSongsbyuserCountry(this.countryId, this.UserId);
    }
    else if (this.id == 4) {
      this.title = "Top 50";
      this.subtitle = "West";
      this.backgroundColor = 'linear-gradient(0deg, #e9d0e6 0%, #e9d0e6 50%, #ffffff 100%)';
      this.lngcode = "WD"
      this.getSongsbylanguageCode(this.lngcode, this.UserId);
    }
    else if (this.id == 5) {
      this.title = "Top 50";
      this.subtitle = "East";
      this.backgroundColor = 'linear-gradient(0deg, #bcbff9 0%, #bcbff9 50%, #ffffff 100%)';
      this.lngcode = "ED"
      this.getSongsbylanguageCode(this.lngcode, this.UserId);
    }
    else if (this.id == 6) {
      this.title = "Top 50";
      this.subtitle = "Gen Z";
      this.showReleasedOn = true;
      this.backgroundColor = 'linear-gradient(0deg, #b2d5f7 0%, #b2d5f7 50%, #ffffff 100%)';
      this.getMostPlayedSongsByUserBornYear(this.UserId);
    }
    else if (this.id == 7) {
      this.title = "Top 25";
      this.subtitle = "East Classics";
      this.showReleasedOn = true;
      this.backgroundColor = 'linear-gradient(0deg, #bcb4fe 0%, #bcb4fe 50%, #ffffff 100%)';
      this.lngcode = "ED"
      this.getMostPlayedSongsByLangAndYear(this.lngcode, this.UserId);
    }
    else if (this.id == 8) {
      this.title = "Top 10";
      this.subtitle = "Playlists";
      this.backgroundColor = 'linear-gradient(0deg, #cde592 0%, #cde592 50%, #ffffff 100%)';
      this.getMostPlayedPlaylist();
    }
    else if (this.id == 9) {
      this.title = "Top 25";
      this.subtitle = "Church music";
      this.backgroundColor = 'linear-gradient(0deg, #eed4a6 0%, #eed4a6 50%, #ffffff 100%)';
      this.categoryCode = "ch";
      this.fetchCount = 25;
      this.getMostPlayedSongsByCategory(this.categoryCode, this.UserId, this.fetchCount);
    }
    else if (this.id == 10) {
      this.title = "Top 25";
      this.subtitle = "West Classics";
      this.showReleasedOn = true;
      this.backgroundColor = 'linear-gradient(0deg, #edb6e0 0%, #edb6e0 50%, #ffffff 100%)';
      this.lngcode = "WD";
      this.getMostPlayedSongsByLangAndYear(this.lngcode, this.UserId);
    }
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    //this.getHomeDetails(this.userDetails.userId);
    if (!this.userImage) {
      this.userImage = "../../../assets/images/NoUserFound.png";
    }
    this.playerService.getIsblogorCountryScreen().subscribe(value => {debugger
      this.IsActiveSongId = value; console.log(value)
    });
    window.scroll(0, 0);
    // this.playerService.registerPlaySongCallbackno((song) => {
    //   console.log(this.songData)
    //   if (song == true) {
    //     //this.currentIndex = this.songData.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    //     this.isSongplay = true;
    //     //this.playAllsongs(this.songData,'play');
    //   }
    //   else {
    //     this.isSongplay = false;
    //   }
    // });
  }
  checkSongList(songList){debugger
    this.currentIndex = songList.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
    console.log(songList);
    if(this.currentIndex!=-1){
        this.playAllsongs(songList,'play');
    }
    else{
      this.playAllsongs(songList,'pause');
    }
  }
  getmostplayedSongs() {
    this.isLoading = true;
    this._songservice.GetMostPlayedTop50songs(this.UserId).subscribe((data: any) => {
      this.isLoading = false;
      this.songData = data.mostPlayedSongs; console.log(this.songData, this.songData[0].thumbImage)
      this.totalcount = data.mostPlayedSongs.length;
     //this.checkSongList(this.songData)
    })
  }
 
  getSongsbylanguageCode(lngcode, userId) {
    this.isLoading = true;
    this._songservice.GetMostPlayedSongsByLangs(lngcode, userId).subscribe((data: any) => {
      this.isLoading = false;
      this.songData = data.mostPlayedSongs; console.log(this.songData)
      this.totalcount = data.mostPlayedSongs.length;
      //this.checkSongList(this.songData);
    })
  }
  getSongsbyuserCountry(countryId, userId) {
    this.isLoading = true;
    this._songservice.GetMostPlayedSongsByUsersCountry(countryId, userId).subscribe((data: any) => {
      this.isLoading = false;
      this.songData = data.mostPlayedSongs; console.log(this.songData)
      this.totalcount = data.mostPlayedSongs.length;
      //this.checkSongList(this.songData);
    })
  }
  getMostPlayedSongsByCategory(songCategory, userId, fetchCount) {
    this.isLoading = true;
    this._songservice.GetMostPlayedSongsOnTobyByCategory(songCategory, userId, fetchCount).subscribe((data: any) => {
      this.isLoading = false;
      this.songData = data.mostPlayedSongs; console.log('category', this.songData, data)
      this.totalcount = data.mostPlayedSongs.length;
      //this.checkSongList(this.songData);
    })
  }
  getMostPlayedSongsByUserBornYear(userId) {
    this.isLoading = true;
    this._songservice.GetMostPlayedSongsByUsersBornYear(userId).subscribe((data: any) => {
      this.isLoading = false;
      this.songData = data.mostPlayedSongs; console.log(this.songData, data)
      this.totalcount = data.mostPlayedSongs.length;debugger
      //this.checkSongList(this.songData);
    })
  }
  getMostPlayedSongsByLangAndYear(lngcode, userId) {
    this.isLoading = true;
    this._songservice.GetMostPlayedSongsByLangCodeNyear(lngcode, userId).subscribe((data: any) => {
      this.isLoading = false;
      this.songData = data.mostPlayedSongs; console.log(this.songData, data)
      this.totalcount = data.mostPlayedSongs.length;
     // this.checkSongList(this.songData);
    })
  }
  getMostPlayedPlaylist() {
    this.isLoading = true;
    this._songservice.GetMostPlayedPlaylistOnToby().subscribe((data: any) => {
      this.isLoading = false;
      // data.mostPlayedSongs.forEach(s => 
      //   s.length=s.length.splice(0,3)
      // );
      this.songData = data.mostPlayedSongs; console.log(this.songData, data)
      this.totalcount = data.mostPlayedSongs.length;
      //this.checkSongList(this.songData);
    })
  }
  routeToPlaylist(playlist) {
    if (this.subtitle == "Playlists") {
      this.router.navigate(['/playlist/allplaylistsongs'], { queryParams: { Id: playlist.playlistId, admin: playlist.name } });
    }
  }

  private areArraysSameDeep(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  playAllsongs(items, play) {
    debugger;
    if (play == 'play') {
      this.currentIndex = items.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
      console.log(this.currentIndex)
      if (!this.areArraysSameDeep(this._playerService.msaapPlaylist, items)) {
        this._playerService.msaapPlaylist = [];
        if (this.currentIndex != -1) {
          this.CheckSubscription(items, this.currentIndex);
        }
        else {
          this.CheckSubscription(items, 0);
        }
        this.isSongplay = true;
        //this.playerService.playSongCallback(true);
      }
      else if (this._playerService.msaapPlaylist == items) {
        //this.CheckSubscription(items, currentIndex);
        this.playerService.playSongCallback(true);
      }
      else {
        this.playerService.playSongCallback(true);
      }
    }
    else {
      this.playerService.currentTime = this.player.nativeElement.currentTime;
      console.log('onpause', this._playerService.msaapPlaylist, items.length);
      this.isSongplay = false;
      this.playerService.playSongCallback(false);
    }
    console.log(this.isSongplay)
  }
  CheckSubscription(song, i) {
    debugger;
    if (song.length > 0) {
      this.playSong(song, i);
    }
    else {
      this.playSong(song, i);

    }
    //this.playerService.playPause();
    // if (this.substatus == "False") {

    //     document.getElementById("Subscriptioncheck").style.display = "block";
    //     //this.router.navigate(['/account/upgradeplan']);
    // }
    // else {
    //     this.songIsthere = true;

    // }
  }

  playSong(songs, index) {
    debugger;
    // this._playerService.playSongs(songs);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    // this.playerService.playSongCallback(true);
    let currentIndex = songs.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (currentIndex === index && this.isplayed) {
      this.isplayed = false;
      this.playerService.playSongCallback(this.isplayed);
      return;
    }
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.isplayed = true; 
    this.playerService.playSongCallback(this.isplayed);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  back() {
    this.location.back();
  }
}
