import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Users } from './models/users';
import { UserService } from './Shared/user.service';
import { Role } from './models/role';
import { SongsService } from './Shared/songs.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit {
  title = 'TobyMusic';
  url = "../assets/js/custom.js";
  loadAPI;
  theme1: boolean = true;
  userDetails = new Users();
  isUser: boolean;
  containervisibility=true;
  songs: any;
  isVerifyEmailRoute: boolean = false;
  isNotVerifyEmailRoute: boolean = true;
  constructor(public router: Router, public translate: TranslateService, public userService: UserService
    ,private _songsService: SongsService) {
    translate.addLangs(['en', 'fr', 'sw', 'ar', 'as']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/en|fr|sw|ar|as/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      translate.setDefaultLang('en');
    }
    let link = this.router.url; 
    
  }

  ngOnInit() {
    localStorage.setItem('IsPlaying', 'false');
    localStorage.setItem('IsSongPlaying', 'false');
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.router.events.subscribe(() => {
      this.isVerifyEmailRoute = this.router.url.startsWith('/verify-email/');
      this.isNotVerifyEmailRoute = !this.isVerifyEmailRoute;
    });
    // if(this.userDetails.userType == "USER"){
    //   this.isUser = true;
    // }
    const isUserpresent = localStorage.getItem('Isuser') === 'true';
    if(isUserpresent == true){
      this.userService.IsUser(true);
    }
   
    this.loadAPI = new Promise(resolve => {
      if (this.userDetails) {
        this.Changetheme();
      }
    });
    this.userService.getIsRouting().subscribe(value => {
      this.containervisibility = value;
    });
    this.userService.getIsUser().subscribe(value => {
      setTimeout(() => {
        this.isUser = value;
      });
      
    });
    //this.getSongs();
  }
  public loadScript() {
    let node = document.createElement("script");
    node.src = this.url;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }
  Changetheme() {
    if (this.userDetails.themeId == '2') {
      this.userService.theme1 = false;
    }
    else {
      this.userService.theme1 = true;
    }
  }

  // getSongs() {
  //   //this._songsService.getArtists();
  //   var searchtext = ""
  //   this._songsService.getSongs(searchtext).subscribe(async (data: any) => {
  //     this.songs = data.songs;
  //     console.log('song', data);

  //    await this.dexieService.table('Songs').bulkAdd(this.songs).then(() => {
  //       console.log('Records added successfully', );
  //     });
  //   });
  // }

}
