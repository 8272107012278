import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Users } from 'src/app/models/users';
import { LoginService } from 'src/app/Shared/login.service';
import { UserService } from 'src/app/Shared/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  email: any;
  userDetails : any;
  userId: number;
  showHideRegistraionPage: boolean;
  showHidePlan: boolean;
  planName: string;

  constructor(private _router: Router,
    private _loginService: LoginService,
    private _toastr: ToastrService, public userService: UserService,
    private spinner: NgxSpinnerService, private route: ActivatedRoute,) {
      debugger;
   // this.userDetails = this.userService.getVerifyedUserDetails();
   this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.email = this.route.snapshot.queryParamMap.get('email') || '';
    if (this.userDetails) {
      if (this.email === this.userDetails.email) {
        this.register();
      }
    }
  }

  ngOnInit() {


  }

  register() {
    this.spinner.show();
    this._loginService.userRegistration(this.userDetails).subscribe((res: any) => {
      console.log('planname2', res);
      if (res.status == "200" || res.status == "400") {
        this.userDetails = res.userDetail;
        localStorage.clear();
        localStorage.setItem('UserDetails', JSON.stringify(this.userDetails));
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.userId = this.userDetails.userId;
        this._toastr.success("You are successfully registered !");
        res.status == "400" ? this._toastr.warning("Email not sent due to network error") : '';
        // this.showHideRegistraionPage = false;
        // this.showHidePlan = true;
        this.spinner.hide();
        // if (this.planName == "Free Trial" || this.planName == "Toby Free") {
        //   this.freetrial();
        // }
        // else {
          // this._loginService.change(this.planName);
          // this._router.navigate(['/toby-home']);
        // }
      } else if (res.status == 401) {
        // this.logindate = true;
        this._toastr.warning("Email is already exist!");
        this.spinner.hide();
      }
    },
      err => {
        this._toastr.error(err)
        this.spinner.hide();
      });
  }

  navigateToPayment(){
    this._router.navigate(['/toby-home']);
  }

}
