import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/Shared/user.service';
import { Users } from '../models/users';
import { NgxSpinnerService } from "ngx-spinner";
import { SongsService } from 'src/app/Shared/songs.service';
import { ToastrService } from 'ngx-toastr';
import { PlayerService } from '../Shared/player.service';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { Playlist } from '../models/playlist';
import { HomeService } from '../Shared/home.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-liked-songs',
  templateUrl: './liked-songs.component.html',
  styleUrls: ['./liked-songs.component.css']
})
export class LikedSongsComponent implements OnInit {
  userDetails = new Users();
  playlist = new Playlist();
  playListDetails: any;
  isliked = false;
  IsActiveSongId: any;
  UserId: any;
  songId: any;
  path: any;
  AllUserFavouriteSongs = new Array();
  userFullname: any;
  totalcount:any = 0;
  userImage: any;
  isplaytitleinfo: boolean = false;
  isTextColor: boolean[] = [];
  public songsLists: Track = new Track();
  indexxx: number;
  topLikedImage: any;
  noLikeImage1: boolean[] = [];
  favSong: boolean[] = [];
  createchecked: boolean = true;
  updateextchecked: boolean;
  songid: any;
  favId: any;
  likeId: any;

  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('f') form: NgForm;
  marginRightMusicContainer: any;

  currentTime: number = 0;
  @ViewChild('audioPlayer') player: ElementRef;
  ShowPlayer: boolean = true;
  loaderDisplay: boolean = false;
  isSongplay: boolean = false;
  tempUserFav: any = new Array();
  showAllLess = "Show all";
  showAllSongs = "Show all songs";
  minNum: number = 50;
  isLoading: boolean;
  isplayed: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenCss(event.target.innerWidth);
  }

  constructor(private userService: UserService, private spinner: NgxSpinnerService, private toastr: ToastrService, private _songsServicee: SongsService,
    private _playerService: PlayerService, public homeService: HomeService, public playerService: AudioPlayerService) {
    this.updateScreenCss(window.innerWidth);
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    console.log(this.userImage)
    if (!this.userImage) {
      this.userImage = "../../../assets/images/NoUserFound.png";
    }
    this.GetAllUserFavouriteSongs();
    window.scrollTo(0, 0);
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      //console.info('san: ' + value);
      this.IsActiveSongId = value;
      //this.GetAllUserFavouriteSongs();
    });
    if (this.playListDetails == undefined) {
      this.freshplaylist();
    }
    this.playerService.registerPlaySongCallbackno((song) => {
      if (song == true) {
        this.isSongplay = true;
      }
      else {
        this.isSongplay = false;
      }
    });
  }


  updateScreenCss(width: number) {
    const mainElement = document.querySelector('.main') as HTMLElement;
    const musicContainerElement = document.querySelector('.music-container') as HTMLElement;


    // Calculate margin-right dynamically for screen widths between 2049 and 5464
    if (musicContainerElement) {
      if (width >= 2049 && width <= 6144) {
        // Calculate the percentage value for margin-right
        const marginRightPercentage = ((width - 2049) / (6144 - 2049)) * 69; // Adjust 69 as needed
        musicContainerElement.style.marginRight = marginRightPercentage + '%';
      } else {
        musicContainerElement.style.marginRight = ''; // Reset margin-right for other screen widths
      }
      this.marginRightMusicContainer = musicContainerElement.style.marginRight;
    }
  }

  likesong() {
    this.isliked = true;
  }
  GetAllUserFavouriteSongs() {
    //this.spinner.show();
    this.isLoading = true;
    this._songsServicee.GetUserFavoriteSongs(this.UserId).subscribe({
      next: (data: any) => {
        console.log("data:", data);
        this.AllUserFavouriteSongs = data.songs;
        this.tempUserFav = this.AllUserFavouriteSongs.slice(0, this.minNum);
        this.isLoading = false;
        this.AllUserFavouriteSongs.forEach((s, i) => {
          if (s.favId) { this.favSong[i] = true; }
          else { this.favSong[i] = false; }
        })
        this.topLikedImage = this.AllUserFavouriteSongs[0].thumbImage;
        this.totalcount =this.formatSongCount(data.count);
        this.path = data.path;
        console.log(data.songs, this.path, this.topLikedImage);
      }, error: (error) => {
        console.error(error.message);
        //this.spinner.hide();
      }
    });
  }

  freshplaylist() {
    this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
      this.playListDetails = data.playListDetails;
      this.homeService.getLatestPlaylist(this.playListDetails);

    })
  }

  // playAllsongs(items) {
  //   debugger;
  //   this.CheckSubscription(items, 0);
  // }
  // CheckSubscription(song, i) {
  //   debugger;
  //   this.playSong(song, i);
  //   // if (this.substatus == "False") {

  //   //     document.getElementById("Subscriptioncheck").style.display = "block";
  //   //     //this.router.navigate(['/account/upgradeplan']);
  //   // }
  //   // else {
  //   //     this.songIsthere = true;

  //   // }
  // }

  // playSong(songs, index) {
  //   debugger;
  //   if (songs.length > 1) {
  //     this._playerService.msaapPlaylist = [];
  //     for (var i = 0; i < songs.length; i++) {
  //       const tractObj = new Track();
  //       tractObj.index = i;
  //       tractObj.image = this.path + songs[i].thumbImage;
  //       tractObj.artistName = songs[i].artistName;
  //       tractObj.link = songs[i].streamUrl;
  //       tractObj.title = songs[i].title;
  //       tractObj.songId = songs[i].songId;
  //       this._playerService.msaapPlaylist.push(tractObj);
  //     }

  //     if (this.playerService.repeatSong) {
  //       this.playerService.repeatSong = false;
  //     }
  //     this.playerService.setPlaylist(this._playerService.msaapPlaylist);
  //     this.playerService.selectATrack(index + 1);
  //     this._playerService.updateData(this._playerService.msaapPlaylist);
  //   }
  //   else {
  //     this._playerService.msaapPlaylist = [];
  //     const tractObj = new Track();
  //     tractObj.image = this.path + songs.thumbImage;
  //     tractObj.artistName = songs.artistName;
  //     tractObj.link = songs.streamUrl;
  //     tractObj.title = songs.title;
  //     tractObj.songId = songs.songId;
  //     this._playerService.msaapPlaylist.unshift(tractObj);
  //     //this._playerService.msaapPlaylist.push(tractObj);
  //     this.playerService.setPlaylist(this._playerService.msaapPlaylist);
  //     this.playerService.selectATrack(1);
  //     this._playerService.updateData(songs);
  //   }
  // }

  private areArraysSameDeep(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  playAllsongs(items, play) {
    if (play == 'play') {
      if (!this.areArraysSameDeep(this._playerService.msaapPlaylist, items)) {
        this._playerService.msaapPlaylist = [];
        this.CheckSubscription(items, 0);
        setTimeout(() => {
          this.isSongplay = true;
        }, 1000);
      }
      else if (this._playerService.msaapPlaylist == items) {
        this.playerService.playSongCallback(true);
      }
      else {
        this.playerService.playSongCallback(true);
      }
    }
    else {
      this.playerService.currentTime = this.player.nativeElement.currentTime;
      console.log('onpause', this._playerService.msaapPlaylist, items.length, this.currentTime);
      this.isSongplay = false;
      // this.player.nativeElement.paused = false;
      //this.playBtnHandler();
      this.playerService.playSongCallback(false);

    }

  }
  CheckSubscription(song, i) {
    debugger;
    if (song.length > 0) {
      this.playSong(song, i);
    }
    else {
      this.playSong(song, i);

    }
    //this.playerService.playPause();
    // if (this.substatus == "False") {

    //     document.getElementById("Subscriptioncheck").style.display = "block";
    //     //this.router.navigate(['/account/upgradeplan']);
    // }
    // else {
    //     this.songIsthere = true;

    // }
  }

  playSong(songs, index) {
    // this._playerService.playSongs(songs);
    // if (this.playerService.repeatSong) {
    //   this.playerService.repeatSong = false;
    // }
    // this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    // this.playerService.selectATrack(index + 1);
    // this._playerService.updateData(this._playerService.msaapPlaylist);
    // //this.playerService.Issongplay(true);
    // console.log('Emitting playSong event for:');
    // //this.playerService.playSong(true);
    // this.playerService.playSongCallback(true);
    // // this.player.nativeElement.paused = true;
    // // this.playBtnHandler();


    let currentIndex = songs.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (currentIndex === index && this.isplayed) {
      this.isplayed = false;
      this.playerService.playSongCallback(this.isplayed);
      return;
    }
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.isplayed = true;
    this.playerService.playSongCallback(this.isplayed);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

  showMoresongs() {
    this.isLoading = true;
    this.minNum = this.minNum + 50;
    if (this.minNum > this.AllUserFavouriteSongs.length || this.minNum == this.AllUserFavouriteSongs.length) {
      this.minNum = this.AllUserFavouriteSongs.length
      this.showAllLess = "Show less";
    }
    // Introduce an artificial delay of 5-10 seconds
    const delay = Math.floor(Math.random() * 4000) + 1000; // Random delay between 5000ms (5s) and 11000ms (11s)

    setTimeout(() => {
      this.tempUserFav = this.AllUserFavouriteSongs.slice(0, this.minNum);
      this.isLoading = false;
    }, delay);
  }

  showAllsongs() {
    debugger;
    if (this.showAllLess == "Show all") {
      this.showAllLess = "Show less";
      this.minNum = this.AllUserFavouriteSongs.length;
      this.tempUserFav = this.AllUserFavouriteSongs//.slice(0, 20);
    }
    else {
      this.showAllLess = "Show all";
      this.tempUserFav = this.AllUserFavouriteSongs.slice(0, 5);
      this.minNum = 5
    }
  }

  formatSongCount(length: number): string {
    if (length < 10 && length>0) {
      // Format numbers less than 10 with a leading zero
      return '0' + length;
    } else if (length >= 10000) {
      // Round the number to the nearest hundred for numbers >= 10,000
      const roundedLength = Math.round(length / 100) * 100;
      return new Intl.NumberFormat('en-US').format(roundedLength);
    } else {
      // Simply format numbers >= 10 and < 10,000 with commas
      return new Intl.NumberFormat('en-US').format(length);
    }
  }

}
