import { Component, OnInit } from '@angular/core';
import { LoadScriptService } from '../../../loadScript.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SongsService } from '../../../Shared/songs.service';
import { ArtistsService } from '../../../Shared/artists.service';
import { Song } from '../../../models/song';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { enableDebugTools } from '@angular/platform-browser';
import { Users } from '../../../models/users';
import { Location } from '@angular/common';
import { AlbumService } from 'src/app/Shared/album.service';
import { Approvalstatus } from 'src/app/models/approvalstatus';
import { splitAtColon } from '@angular/compiler/src/util';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigService } from 'src/app/app.config';
import { HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http'
@Component({
  selector: 'app-add-update-songs',
  templateUrl: './add-update-songs.component.html',
  styleUrls: ['./add-update-songs.component.css']
})
export class AddUpdateSongsComponent implements OnInit {
  public imagePath;
  public progress: number;
  public message: string;
  imgURL: any;
  imgURLS: any;
  userDetails = new Users();
  isDesabled: boolean = false;
  isDesabledalbum: boolean = false;
  songtitle: any = "Add Song"
  artists: any;
  categories: any;
  ArtistId: number;
  albums: any;
  songForm: FormGroup;
  submitted = false;
  addSongProfile: boolean = true;
  SongtitleError: boolean;
  songphoto;
  noImage: boolean = true;
  noImages: boolean = true;
  noUrl: boolean = true;
  path: any;
  isChecked: boolean = true;
  fileToUpload: File = null;
  fileToUploads: File = null;
  photoUrl: any;
  loginasartist: boolean = false;
  objectUrl;
  duration: number = 0;
  file: File;
  timg: any;
  limg: any;
  files: any;
  limgN:any;

  restricts: boolean = false;
  isSelected: boolean;
  arabicRegex;
  startyear: any;
  Years: any[] = [];
  Index: any = 0;
  songlangugae: any;
  langugae: any;
  timgN: any;
  constructor(private albumService: AlbumService, private _router: Router, private loadScript: LoadScriptService, private _songsService: SongsService, private _artistsService: ArtistsService, private formBuilder: FormBuilder, private toastr: ToastrService
    , private route: ActivatedRoute, public location: Location,
    public translate: TranslateService,
    private spinner: NgxSpinnerService, private configService: ConfigService) {
    this.song.songId = this.route.snapshot.paramMap.get("id");
    this.arabicRegex = '[\u0600-\u06FF]';
    this.startyear = configService.getstartDate();
  }
  song = new Song();
  
  ngOnInit() {
    this.song.isComingUp = this.isChecked;
    this.getArtists();
    this.song.artistId = this.albumService.artistId;
    this.song.albumId = this.albumService.albumId;
    this.dropdownyear();
    if (this.song.artistId == 0)
      this.isDesabled = false;
    else
      this.isDesabled = true;
    if (this.song.albumId == 0)
      this.isDesabledalbum = false;
    else {
      this.isDesabledalbum = true;
      this.getAllAlbums(this.song.artistId);
    }
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));

    if (this.userDetails["artistId"] != undefined) {
      this.song.artistId = (this.userDetails["artistId"]);
      this.isDesabled = true;
      this.getAllAlbums(this.song.artistId);
      this.loginasartist = true;
    }

    this.getallCategories();
    this.getsongLanguage();

    if (this.song.songId != null) {
      this.songtitle = "Update Song";
      this.isDesabled = true;
      this.isDesabledalbum = true;
      this.addSongProfile = false;
      this._songsService.getSongDetails(this.song.songId).subscribe((data: any) => {
        if (data.songs.isForKid == true) {
          this.isSelected = true;
        }
        else {
          this.isSelected = false;
        }

        this.imgURL = data.path.concat(data.songs.largeImage);
        this.imgURLS = data.path.concat(data.songs.largeImageNew);
        this.isChecked = data.songs.isComingUp;
        console.log('getsong', data.path)
        this.song = data.songs;
        this.song.streamUrl = data.songs.streamUrl;
        this.song.length = data.songs.length;
        if (data.songs.largeImage != null) {
          this.limg = data.songs.largeImage.split('/');
          this.limg = this.limg[this.limg.length - 1];
          this.song.largeImage = this.limg;
        }
        if (data.songs.largeImageNew != null) {
          this.limgN = data.songs.largeImageNew.split('/');
          this.limgN = this.limgN[this.limgN.length - 1];
          this.song.largeImageNew = this.limgN;
        }

        if (data.songs.thumbImage != null) {
          this.timg = data.songs.thumbImage.split('/');
          this.timg = this.timg[this.timg.length - 1];
          this.song.thumbImage = this.timg;
          this.noImage = false;
        } else {
          this.noImage = true;
        }

        if (data.songs.thumbImageNew != null) {
          this.timgN = data.songs.thumbImageNew.split('/');
          this.timgN = this.timgN[this.timgN.length - 1];
          this.song.thumbImageNew = this.timgN;
          this.noImages = false;
        } else {
          this.noImages = true;
        }

        if (data.songs.streamUrl != null) {
          this.song.streamUrl = data.songs.streamUrl.split('/');
          this.song.streamUrl = this.song.streamUrl[this.song.streamUrl.length - 1];
          this.noUrl = false;
        }
        else {
          this.noUrl = true;
        }
        this.song = data.songs;
        console.log("getsongfull" , this.song)
        this.getAllAlbums(this.song.artistId);
        this.path = data.path;
      })
    } else {
      this.song.songId = 0;
    }
  }
  getArtists() {
    //this._songsService.getArtists();
    this._songsService.getArtists().subscribe((data: any) => {
      this.artists = data.artistDetails;

    });
  }
  getallCategories() {
    this._songsService.GetallCategories().subscribe((data: any) => {
      this.categories = data.categories;
    });
  }
  getsongLanguage() {
    this._songsService.GetsongLanguage().subscribe((data: any) => {
      this.songlangugae = data.languages;
    });
  }
  artistChange($event) {

    this.ArtistId = $event.target.value;
    this.getAllAlbums(this.ArtistId);
  }
  langugaeChange($event) {

    this.ArtistId = $event.target.value;
    this.getlanguage(this.ArtistId);
  }
  getlanguage(ArtistId) {
    this._songsService.Getlangugae(ArtistId).subscribe((data: any) => {
      this.songlangugae = data.langDetails;
    });
  }
  getAllAlbums(ArtistId) {
    this._songsService.GetAllAbumbs(ArtistId).subscribe((data: any) => {
      this.albums = data.albumDetails;
    });
  }

  Checked(value) {
    this.song.isComingUp = value;
  }

  Checkedkid(event) {
    this.song.isforKid = event;
  }

  onSubmit() {debugger;
    this.spinner.show();
    if (this.noImage) {
      this.song.base64Data = null;
    } else {
      this.song.base64Data = this.imgURL.split(",")[1];
    }
    if (this.noImages) {
      this.song.base64DataForNewRelease = null;
    } else {
      this.song.base64DataForNewRelease = this.imgURLS.split(",")[1];
    }

    if (this.userDetails["artistId"] != undefined) {
      this.song.createdBy = this.userDetails["artistId"].toString();
      this.song.updatedBy = this.userDetails["artistId"].toString();

    }
    if (this.userDetails["userId"] != undefined) {
      this.song.createdBy = this.userDetails["userId"].toString();
      this.song.updatedBy = this.userDetails["userId"].toString();
    }
    //this.song.length=
    this.spinner.show();
    this.song.statusId = Approvalstatus.SUBMIT;
    this.song.lengthString = this.song.length != null ? this.song.length.toString() : "";
    const time = {
      "Hours":16,
      "Minutes":8,
      "Seconds":45,
      "Milliseconds":0,
      "Ticks":581250000000,
      "Days":0,
      "TotalDays":0.6727430555555556,
      "TotalHours":16.145833333333332,
      "TotalMilliseconds":58125000,
      "TotalMinutes":968.75,
      "TotalSeconds":58125
     };
     this.song.length = time;

    console.log('addsong', this.song);
    this._songsService.addupdatesong(this.song).subscribe((res: any) => {

      if (res.status == 200) {
        if (this.song.songId == 0) {
          this.toastr.success("song added successfully");
          this.spinner.hide();

        } else {
          this.toastr.success("song updated successfully");
          this.spinner.hide();
        }
        this.location.back();
        //this._router.navigate(['/admin/songs']);
      }
    }, err => {
      this.toastr.error(err)
      this.spinner.hide();
    })
  }

  //Upload Files

  selectPhoto(file: FileList) {
    this.song.thumbImage = file.item(0).name;
    this.fileToUpload = file.item(0);
    var File = this.fileToUpload.name;
    this.song.fileName = File.split('.')[0];
    this.files = File.split('.');
    this.song.fileType = this.files[this.files.length - 1];
    //this.song.FileType = File.split('.')[1];
    this.noImage = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  //upload Files for New Release
  selectPhotos(file: FileList) {
    this.song.thumbImageNew = file.item(0).name;
    this.fileToUploads = file.item(0);
    var File = this.fileToUploads.name;
    this.song.fileNameNew = File.split('.')[0];
    this.files = File.split('.');
    this.song.fileTypeNew = this.files[this.files.length - 1];
    //this.song.FileType = File.split('.')[1];
    this.noImages = false;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURLS = event.target.result;
    }
    reader.readAsDataURL(this.fileToUploads);
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.song.base64Data = btoa(binaryString);

  }

  //Upload Mp3 File
  selectSong(evt) {
    this.file = evt.target.files[0];
    new Audio(URL.createObjectURL(this.file)).onloadedmetadata = (e: any) => {
      this.duration = e.currentTarget.duration;
      this.songlength(this.duration);
    }

    //var duration = moment.duration(seconds, "seconds");
    var File = evt.target.value;
    this.song.streamUrl = File.substr(12);
    let subStringData = File.substr(12);
    var FileName = subStringData.split('.')[0];
    var songfiletype = subStringData.split('.');
    songfiletype = songfiletype[songfiletype.length - 1];
    //var FileType = subStringData.split('.')[1];
    this.noUrl = false;
    this.song.songName = FileName;
    this.song.songType = songfiletype;
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedForSong.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoadedForSong(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.song.base64DataForSong = btoa(binaryString);
  }


  songlength(duration) {
    var h = Math.floor(duration / 3600);
    var m = Math.floor(duration % 3600 / 60);
    var s = Math.floor(duration % 3600 % 60);
    var minutes = h + ':' + m + ':' + s;
    this.song.length = minutes;
  }
  doSomething(e) {
    const e1 = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = e.keyCode;  // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      this.restricts = false;
      return;

    }
    else {
      this.restricts = true;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      this.restricts = true;
      return;
    }
    e.preventDefault();
  }
  back() {
    //this._router.navigate(['/admin/songs']);
    this.location.back();
  }

  dropdownyear() {
    this.startyear = 1900;
    let endYear = new Date().getFullYear();
    let year = endYear - this.startyear;
    for (let i = 0; i <= year; i++) {
      this.Years[this.Index] = this.startyear + i;
      this.Index++;

    }
    this.Years = this.Years.reverse();
  }

  

}
