import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../Shared/login.service'
import { Users } from '../../../models/users';
import { Country } from '../../../models/country';
import { ToastrService } from 'ngx-toastr';
import { LoadScriptService } from 'src/app/loadScript.service';
import { SubscriptionService } from '../../../Shared/subscription.service';
import { Subscriptions } from '../../../models/subscriptions';
import { PaymentTransaction } from '../../../models/payment-transaction';
import { Usersubscription } from '../../../models/usersubscription';
import { Role } from 'src/app/models/role';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../Shared/user.service';
import { ConfigService } from 'src/app/app.config';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var paypal;
@Component({
    selector: 'app-tobyhome',
    templateUrl: './tobyhome.component.html',
    styleUrls: ['./tobyhome.component.css']
})
export class TobyhomeComponent implements OnInit {
    @ViewChild('closeBtn') closeBtn: ElementRef;
    @ViewChild('closeBtn1') closeBtn1: ElementRef;
    @ViewChild('closeResetBtn') closeResetBtn: ElementRef;
    @ViewChild('scrollToTop') scrollToTop: ElementRef;
    @ViewChild('paypal') paypalElement: ElementRef;
    userDetails = new Users();
    userId: number;
    subscription: any;
    singledevice: any;
    multipledevice: any;
    Singlesubscription = 0;
    Multiplesubscription = 0;
    //Subscription Varibles
    offerprice: any;
    discountedPrice;
    savedPrice;
    offerprice1: any;
    discountedPrice1;
    savedPrice1;
    showPaymentSection: boolean = false;
    showbraintree: boolean = false;
    showskip: boolean = true;
    showpaywith: boolean = false;
    showconfirmpayment: boolean = false;
    showHidePlan: boolean = true;
    showHideRegistraionPage: boolean = true;
    subDuration: string;
    saveprice: any;
    MultiplediscountedPrice1: any;
    Multipleofferprice: any;
    MultiplesavedPrice: number;
    MultiplediscountedPrice: any;
    userSubscriptions = new Subscriptions();
    paymentTransaction = new PaymentTransaction();
    subcriptiondetails = new Usersubscription();
    usersubid: any;
    email: any;
    username: any;
    paymentResponse: any;
    // userDetails: any;
    role: any;
    superAdminLoggedIn: boolean;
    singledisprice: boolean = true;
    multipledisprice: boolean = false;
    freetrailchecked: boolean = true;

    createchecked: boolean;
    updateextchecked: boolean;
    price;
    planId: any;
    subcripId: any;
    chargeAmount: any;
    // status:any;
    // apay=[];
    basicAuth = 'Basic QVlvb25tN3dKaWpfb1FnNjNYLTBCcjBlVGdHcjVzRS1rT1ZZb3g0OVk4eWZ5SUpVVHY2UElaQ1pxb09vN19Cb25XbE92bDhhOW5ZaDZ3UU46RURUbEF3N3NwUGN4ajdkRTY3VXdJYW1obEJrdUtTRXFUUWxNNmd1NHhWdURuRlV6cnJXMlZqaFMwek1rR3l6YWtqSlVnLWxHM0FZQjQtcFk=';
    emailid: any;
    massage: string;
    BrainTreeplanId: any;
    BraintrreId: any;
    b: any = 1;
    subplanname: any;
    subplanprice: any;
    subplansingle: any;
    subplansingleprice: any;
    subplanmultiprice: any;
    subplansingleofferedprice: any;
    subplanmultiofferprice: any;
    savedPriceMultipledevice: number;
    currentDate = new Date();
    currantDate: any;
    endDate: any;
    date: Date;
    Date: Date;
    baseUrl: string;
    clientUrlToken: string;
    result: any;
    terms: any;
    lanCode: any;
    Code: any;
    languageCode: any = 'en';
    abouttoby: any;
    termscondbt: boolean = false;
    termscondbtns: boolean = false;
    termscondbtcoks: boolean = false;
    termscondbtads: boolean = true;
    years: number[] = [];
    Toby: boolean = false;
    Communities: boolean = false;
    LegalInformation: boolean = false;
    requiredError: boolean = false;
    private purchaseSubscription: Subscription;
    selectedPlanId: any;
    termscondbtart: boolean = false;
    termscondbtinv: boolean = false;
    termscondbtabt: boolean = false;
    termscondbtwork: boolean = false;
    termscondbtcontact: boolean = false;
    shuffledPlans: any[];
    subscriptionPlans: any[];

    constructor(
        private _router: Router,
        private _loginService: LoginService,
        private _toastr: ToastrService,
        private loadScript: LoadScriptService,
        private subscriptionService: SubscriptionService,
        private http: HttpClient,
        public translate: TranslateService,
        private datePipe: DatePipe,
        public userService: UserService,
        private configService: ConfigService,
        private sanitizer: DomSanitizer
    ) {
        this.baseUrl = configService.getServiceBaseUrl();
        this.clientUrlToken = this.baseUrl + 'api/Paypal/GenerateToken';
    }

    ngOnInit() {
        // this.ntivepay();
        window.scrollTo(0,0);
        this.date = new Date();
        this.date.setDate(this.date.getDate() + 3);
        this.loadScript.getServiceBaseUrl();
        this.getSubscriptionPlans();
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.emailid = this.userDetails.email;
        this.username = this.userDetails.userName;
        this.userId = this.userDetails.userId;
        this._loginService.SubscriptionName.subscribe(data => {
            debugger;
            console.log(data, 'plannames')
            if (data) {
                if (data == "Toby Free" || data == "Free Trial") {
                    this.freetrial();
                }
                else if (data == "Toby Premium") {
                    this.checked();
                }
                else if (data == "Toby Family") {
                    this.updatechecked();
                }
                else {
                    this.payDuoplan();
                }
            }
        })
        if (this.role == Role.superAdmin) {
            this.superAdminLoggedIn = true;
        }
        this.moveFreeTrialToFront();
        this.subplanname = this.userDetails.subscriptionPlans[0].planName;
        this.subplanprice = this.userDetails.subscriptionPlans[0].price;
        this.subplansingle = this.userDetails.subscriptionPlans[1].planName;
        this.subplansingleprice = this.userDetails.subscriptionPlans[1].price;
        this.subplansingleofferedprice = this.userDetails.subscriptionPlans[1].offeredprice;

        this.savedPrice = this.subplansingleprice - this.subplansingleofferedprice;

        this.subplanmultiprice = this.userDetails.subscriptionPlans[2].price;
        this.subplanmultiofferprice = this.userDetails.subscriptionPlans[2].offeredprice;
        this.savedPriceMultipledevice = this.subplanmultiprice - this.subplanmultiofferprice;


        this.currantDate = this.datePipe.transform(new Date(), "MMM d, y");

        this.date = new Date();

        this.Date = new Date();

        this.Date = new Date();

        this.endDate = (this.Date.setDate(this.Date.getDate() + 30));
        console.log('prices', this.userDetails.subscriptionPlans);

    }
    ngAfterContentInit() {
        this.scrollToTop.nativeElement.scrollIntoView({ behavior: 'auto', block: 'center' })
    }
    languageChange(language: string) {
        localStorage.setItem('locale', language);
        this.translate.use(language);
    }
    ntivepay() {
        // this.planId = 'P-3NS34795KB814132ALVTDMKI';
        const self = this;
        this.planId = 'P-5LD81830JH402373RLVT2JXQ';
        paypal.Buttons({
            createSubscription: function (data, actions) {
                return actions.subscription.create({
                    'plan_id': self.planId,
                    style: {
                        size: 'small',
                        tagline: 'true'
                    }
                });
            },
            onApprove: (data, actions) => {

                this.usersubscription.UserId = "3";
                this.usersubscription.SubscriptionPlanId = "1";

                this.usersubscription.SubscriptionStartDate = '2019-09-23';
                this.usersubscription.BillingStartDate = '2019-09-23';
                this.usersubscription.BillingEndDate = '2019-09-23';
                this.usersubscription.PayableAmount = 1;
                this.usersubscription.SubscriptionStatus = status;

                let test = {
                    "userId": this.userId,
                    "SubPlan": this.usersubscription.SubscriptionPlanId,
                    "PayPalSubscriptionId": data.subscriptionID,
                    "SubscriptionStartDate": this.usersubscription.BillingStartDate,
                    "BillingStartDate": this.usersubscription.BillingStartDate,
                    "BillingEndDate": this.usersubscription.BillingEndDate,
                    "PayableAmount": this.usersubscription.PayableAmount,
                    "SubscriptionStatus": this.usersubscription.SubscriptionStatus,
                    "subscriptionID": data.subscriptionID,
                    //  "SubscriptionPlanId":data.orderID
                }

                data = test;

                alert('You have successfully created subscription ' + data.subscriptionID);
                self.getSubcriptionDetails(data.subscriptionID);
                self.paypalsubscription(data);

            },
            onCancel: function (data) {
                // Show a cancel page, or return to cart
            },
            onError: function (err) {
                // Show an error page here, when an error occurs
            }
        }).render(this.paypalElement.nativeElement);
    }
    // ============Start Get Subcription Details Method============================
    responce;
    status;
    billingStartDate;
    getSubcriptionDetails(subcriptionId) {
        var self = this;
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {

                alert(JSON.stringify(this.responseText));
                self.responce = JSON.parse(this.responseText);

            }

            self.status = self.responce.status
            self.billingStartDate = self.responce.start_time;
            let planid = self.responce.plan_id;

        };

        xhttp.open('GET', 'https://api.sandbox.paypal.com/v1/billing/subscriptions/' + subcriptionId, true);
        xhttp.setRequestHeader('Authorization', this.basicAuth);

        xhttp.send();
    }
    // ============END Get Subcription Details Method========================

    // ============Start Unsubcribe Method===================================
    Unsubcribe() {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 204) {

                alert('Successfully Unsubcribe ');
            }
        };
        xhttp.open('POST', 'https://api.sandbox.paypal.com/v1/billing/subscriptions/' + this.subcripId.toString().trim() + '/cancel', true);
        xhttp.setRequestHeader('Authorization', this.basicAuth);
        xhttp.setRequestHeader('Content-Type', 'application/json');
        xhttp.send();

    }
    // ============End Unsubcribe Method===================================



    logOut() {

        if (this.superAdminLoggedIn) {
            localStorage.clear();
            this._router.navigate(['/admin/admin-login']);
        } else {
            localStorage.clear();
            this._router.navigate(['/login']);
        }
    }

    public usersubscription = {
        UserSubscriptionId: '',
        UserId: '',
        SubscriptionPlanId: '',
        PayPalSubscriptionId: '',
        SubscriptionStartDate: '',
        BillingStartDate: '',
        BillingEndDate: '',
        PayableAmount: 1,
        SubscriptionStatus: '',
        isActive: '',
    };
    paypalsubscription(data) {
        this.usersubscription.UserId = "3";
        this.usersubscription.SubscriptionPlanId = "1";
        this.usersubscription.SubscriptionStartDate = '';
        this.usersubscription.BillingStartDate = '';
        this.usersubscription.BillingEndDate = '';

        this.usersubscription.SubscriptionStatus = "Active";
        this._loginService.paypalsubscription(data).subscribe((res: any) => {
        })

    }
    addForPayment() {
        debugger;
        this.showPaymentSection = true;
        this.showHidePlan = false;
        this.chargeAmount = this.userDetails.subscriptionPlans[this.b].offeredprice;
    }
    confirmpayment() {
        debugger
        this.showconfirmpayment = true;
        this.showPaymentSection = false;
    }
    checked() {
        window.scroll(0, 0);
        this.b = 1;
        this.createchecked = true;
        this.updateextchecked = false;
        this.showskip = false;
        this.showbraintree = true;
        this.showpaywith = true;
        this.addForPayment();
    }
    updatechecked() {
        window.scroll(0, 0);
        this.b = 2;
        this.updateextchecked = true;
        this.createchecked = false;
        this.showskip = false;
        this.showbraintree = true;
        this.showpaywith = true;
        this.addForPayment();
    }
    payDuoplan() {
        window.scroll(0, 0);
        this.b = 6;
        this.updateextchecked = true;
        this.createchecked = false;
        this.showskip = false;
        this.showbraintree = true;
        this.showpaywith = true;
        this.addForPayment();
    }
    freetrailbutton(planName) {
        debugger;
        if (planName == "Free Trial") {
            this.b = 0;
            this.updateextchecked = false;
            this.createchecked = false;
            this.showskip = true;
            this.showbraintree = true;
            this.showpaywith = true;
            this.freetrial();
        }

        else if (planName == "Toby Premium") {
            this.checked();
        }
        else if (planName == "Toby Family") {
            this.updatechecked();
        }
        else {
            this.payDuoplan();
            //for toby Duo
        }

    }
    freetrial() {
        debugger;
        this.b = 0;
        this.userDetails.subscriptionPlans[0].brainTreePlanId;

        this.subcriptiondetails.UserId = this.userId;
        this.subcriptiondetails.UserId = this.userId;

        this.subcriptiondetails.BrainTreePlanId = this.userDetails.subscriptionPlans[0].brainTreePlanId;
        this.subcriptiondetails.PayableAmount = this.userDetails.subscriptionPlans[0].price;

        this.showskip = true;
        this.showbraintree = false;
        this.showpaywith = false;
        this.subscriptionService.addsubscriptiondetails(this.subcriptiondetails).subscribe((data: any) => {
            var retrievedObject = localStorage.getItem('UserDetails');
            var jsObj = JSON.parse(retrievedObject);
            jsObj.subscriptionPlanId = data.subscriptionDetails.subscriptionPlanId;
            localStorage.setItem('UserDetails', JSON.stringify(jsObj));
            this._router.navigate(['/newhome']);
            this.userService.IsUser(true);
            localStorage.setItem('Isuser', 'true');
        });
    }
    Skip() {
        this._router.navigate(['/toby-home']);
    }
    successmsg(msg: string) {
        this._toastr.success(msg)
    }
    onPaymentStatus(response): void {
        this.paymentResponse = response;

    }

    createPurchase = (nonce: string, chargeAmount: number, Userid: number, email: string, name: string, BraintrreId: string) => {
        debugger;
        this.username = this.userDetails.fullName;
        this.userId = this.userDetails.userId;
        this.email = this.userDetails.email;
        this.chargeAmount = this.userDetails.subscriptionPlans[this.b].offeredprice;

        this.BraintrreId = this.userDetails.subscriptionPlans[this.b].brainTreePlanId;

        var purchase = {
            nonce: nonce, chargeAmount: this.chargeAmount, Userid: this.userId, email: this.email, BraintrreId: this.BraintrreId, name: this.username
        }

        this.purchaseSubscription = this.subscriptionService.Purchase(purchase).subscribe((response: any) => {
            debugger;
            if (response == false) {
                this._toastr.success('Pre-Approved Payment enabled PayPal account required for vaulting.')
                this.showHidePlan = true;
                this.showPaymentSection = false;
            }
            else {
                var retrievedObject = localStorage.getItem('UserDetails');
                var jsObj = JSON.parse(retrievedObject);
                this.massage = 'Payment done Successfully';
                jsObj.braintreeSubscriptionId = response.subscriptionId;
                jsObj.subscriptionPlanId = response.subscriptionPlanId;
                localStorage.setItem('UserDetails', JSON.stringify(jsObj))

                localStorage.setItem('susbscid', response.subscriptionId);
                // localStorage.setItem('subscriptionPlanId', response.subscriptionId);
                this.successmsg(this.massage);
                this._router.navigate([`/newhome`]);
                this.userService.IsUser(true);
                localStorage.setItem('Isuser', 'true');
            }
        },
            err => {
                this._toastr.error('Something  Went Wrong ! Please Try Again Later');
                this.showHidePlan = true;
                this.showPaymentSection = false;
            })
        // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        // return this.http
        //     .post(this.baseUrl + '/api/Paypal/Braintreepayment', { nonce: nonce, chargeAmount: this.chargeAmount, Userid: this.userId, email: this.email, BraintrreId: this.BraintrreId, name: this.username }, { 'headers': headers })
        //     .subscribe((response: any) => {

        //         if (response == false) {
        //             this._toastr.success('Pre-Approved Payment enabled PayPal account required for vaulting.')
        //             this.showHidePlan = true;
        //             this.showPaymentSection = false;
        //         }
        //         else {
        //             var retrievedObject = localStorage.getItem('UserDetails');
        //             var jsObj = JSON.parse(retrievedObject);
        //             this.massage = 'Payment done Successfully';
        //             jsObj.braintreeSubscriptionId = response.subscriptionId;
        //             localStorage.setItem('UserDetails', JSON.stringify(jsObj))

        //             localStorage.setItem('susbscid', response.subscriptionId);
        //             this.successmsg(this.massage);
        //             this._router.navigate([`/newhome`]);
        //             this.userService.IsUser(true);
        //             localStorage.setItem('Isuser', 'true');
        //         }
        //     },
        //         err => {
        //             this._toastr.error('Something  Went Wrong ! Please Try Again Later');
        //             this.showHidePlan = true;
        //             this.showPaymentSection = false;
        //         })
    }


    ngOnDestroy() {
        if (this.purchaseSubscription) {
            this.purchaseSubscription.unsubscribe();
        }
    }

    freeSubcription() {
        this.subcriptiondetails.UserId = this.userId;
        this.subscriptionService.addsubscriptiondetails(this.subcriptiondetails).subscribe((data: any) => {
            var retrievedObject = localStorage.getItem('UserDetails');
            var jsObj = JSON.parse(retrievedObject);
            jsObj.subscriptionPlanId = data.subscriptionDetails.subscriptionPlanId;
            this._router.navigate(['/newhome']);
            this.userService.IsUser(true);
            localStorage.setItem('Isuser', 'true');
        });

    }
    SkipPayment() {
        this._router.navigate([`/newhome`]);
        this.userService.IsUser(true);
        localStorage.setItem('Isuser', 'true');
    }
    IsCheckLang() {
        if (this.translate.currentLang == 'ar') {
            return true;

        } else if (this.translate.currentLang == 'as') {
            return true;
        }
        else {
            return false;
        }
    }

    getContentByCode(Code) {
        this.lanCode = this.languageCode.toUpperCase()
        this.Code = Code + this.lanCode;
        this.userService.GetContentByCode(this.Code).subscribe((data: any) => {
            this.result = data.data.pageContent;
            this.terms = data.data.pageContentTitle;
        })
    }
    getAboutUsByCode(Code) {
        // this.lanCode = this.languageCode.toUpperCase()
        // this.Code = Code + this.lanCode;
        this.userService.GetContentByCode(Code).subscribe((data: any) => {
            this.abouttoby = data.data.pageContent;
        })
    }
    heading: string = "";
    term(Code) {

        if (Code == 'TOBY_WORK_' || Code == 'TOBY_CONTACT_' || Code == 'ABOUT_TOBY_EN_LIGHT') {
            this.Toby = true;
            this.Communities = false;
            this.LegalInformation = false;
            this.heading = "Toby";
        }
        if (Code == 'TOBY_MUSIC_CONDITIONS_AND_TERMS_OF_USE_' || Code == 'TOBY_MUSIC_PRIVACY_POLICY_' || Code == 'TOBY_MUSIC_COOKIE_POLICY_' || Code == 'TOBY_MUSIC_ADVERTISING_') {
            this.Toby = false;
            this.Communities = false;
            this.LegalInformation = true;
            this.heading = "Legal Information";
        }
        if (Code == 'TOBY_ARTISTS_' || Code == 'TOBY_INVESTORS_') {
            this.Toby = false;
            this.Communities = true;
            this.LegalInformation = false;
            this.heading = "Communities";
        }
        //console.log(this.Toby, this.Communities, this.LegalInformation, "Tobyyyy")
        if (Code != 'ABOUT_TOBY_EN_LIGHT') {
            this.getContentByCode(Code);
        }
        if ('TOBY_MUSIC_CONDITIONS_AND_TERMS_OF_USE_' == Code) {
            this.termscondbt = true;
            this.termscondbtns = false;
            this.termscondbtcoks = false;
            this.termscondbtads = false;

        }
        else if ('TOBY_MUSIC_PRIVACY_POLICY_' == Code) {
            this.termscondbtns = true;
            this.termscondbt = false;
            this.termscondbtcoks = false;
            this.termscondbtads = false;

        }
        else if ('TOBY_MUSIC_COOKIE_POLICY_' == Code) {
            this.termscondbtcoks = true;
            this.termscondbtns = false;
            this.termscondbt = false;
            this.termscondbtads = false;

        }
        else if ('TOBY_MUSIC_ADVERTISING_' == Code) {
            this.termscondbtcoks = false;
            this.termscondbtns = false;
            this.termscondbt = false;
            this.termscondbtads = true;

        }
        else if ('TOBY_ARTISTS_' == Code) {
            this.termscondbtart = true;
            this.termscondbtinv = false;
        }
        else if ('TOBY_INVESTORS_' == Code) {
            this.termscondbtinv = true;
            this.termscondbtart = false;
        }
        else if ('ABOUT_TOBY_EN_LIGHT' == Code) {
            this.termscondbtabt = true;
            this.termscondbtwork = false;
            this.termscondbtcontact = false;
        }
        else if ('TOBY_WORK_' == Code) {
            this.termscondbtabt = false;
            this.termscondbtwork = true;
            this.termscondbtcontact = false;
        }
        else if ('TOBY_CONTACT_' == Code) {
            this.termscondbtabt = false;
            this.termscondbtwork = false;
            this.termscondbtcontact = true;
        }
        else {
            this.termscondbt = false;
            this.termscondbtns = false;
            this.termscondbtcoks = false;
            this.termscondbtads = false;

        }
    }

    moveFreeTrialToFront() {
        const freeTrialIndex = this.userDetails.subscriptionPlans.slice(0, 3).findIndex(plan => plan.planCode === 'FREE_TRIAL');
        if (freeTrialIndex > -1) {
            const [freeTrialPlan] = this.userDetails.subscriptionPlans.splice(freeTrialIndex, 1);
            this.userDetails.subscriptionPlans.unshift(freeTrialPlan);
        }

        const freeTrialPlan = this.userDetails.subscriptionPlans.find(plan => plan.planCode === 'FREE_TRIAL');
        if (freeTrialPlan) {
            this.selectedPlanId = freeTrialPlan.subscriptionPlanId;
        }
    }
    sanitizeContent(content: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
    shufflePlans() {
        debugger;
        const order = ['Free Trial', 'Toby Premium', 'Toby Duo', 'Toby Family']; // Desired order of plan names
        this.shuffledPlans = order.map(name => this.subscriptionPlans.find(plan => plan.planName === name));
    }
    getSubscriptionPlans() {
        this.subscriptionService.getSubscriptionplan().subscribe((subscriptions: any) => {
            this.subscriptionPlans = subscriptions.subscriptionDetails;
            this.shufflePlans();
            console.log(this.shuffledPlans, this.subscriptionPlans);
        })
    }

}

