import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ArtistMainPageComponent } from './artist-main-page/artist-main-page.component';
import { GetAccessComponent } from './get-access/get-access.component';
import { ArtistRegisterComponent } from './artist-register/artist-register.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';

@NgModule({
    imports: [

        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [RouterModule],
    declarations: [],

})
export class LoginModule { }