import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { Users } from 'src/app/models/users';
import { SongsService } from 'src/app/Shared/songs.service';
import { PlayerService } from '../../Shared/player.service';
import { Track } from 'Player/ngx-audio-player/src/public_api';

@Component({
  selector: 'app-top-category-songs',
  templateUrl: './top-category-songs.component.html',
  styleUrls: ['./top-category-songs.component.css']
})
export class TopCategorySongsComponent implements OnInit {

  fetchCount: number = 50;
  songData = new Array();
  totalcount: any;
  code: string;
  userDetails = new Users();
  UserId: any;
  userFullname: any;
  userImage: any;
  IsActiveSongId: any;
  categoryIconStyles: any;
  isSongplay: boolean = false;
  @ViewChild('audioPlayer') player: ElementRef;
  isLoading: boolean = false;
  isplayed: boolean = false;

  constructor(private _songservice: SongsService, private route: ActivatedRoute,
    public playerService: AudioPlayerService, private _playerService: PlayerService,) {
    this.playerService.registerPlaySongCallbackno((song) => {
      console.log(song)
      if (song == true) {
        this.isSongplay = true;
      }
      else {
        this.isSongplay = false;
      }
    });
  }

  ngOnInit() {
    //window.scroll(0, 0);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.code = this.route.snapshot.paramMap.get("id");
    this.getMostPlayedSongsByCategory(this.code, this.UserId, this.fetchCount);
    this.userFullname = this.userDetails["fullName"];
    this.userImage = this.userDetails["image"];
    //this.getHomeDetails(this.userDetails.userId);
    if (!this.userImage) {
      this.userImage = "../../../assets/images/NoUserFound.png";
    }
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value; console.log(value)
    });
  }

  getMostPlayedSongsByCategory(songCategory, userId, fetchCount) {
    this.isLoading = true;
    this._songservice.GetMostPlayedSongsOnTobyByCategory(songCategory, userId, fetchCount).subscribe((data: any) => {
    this.isLoading = false;
      this.songData = data.mostPlayedSongs;
      switch (this.code) {
        case 'dc':
          this.categoryIconStyles = { height: '150px', opacity: '0.600', margin: '0 0 0 10%' };
          break;
        case 'kid':
          this.categoryIconStyles = { padding: '0 9% 0 0', opacity: '0.600' };
          break;
        case 'hp':
          this.categoryIconStyles = { opacity: '0.600' };
          break;
        case 'roc':
          this.categoryIconStyles = { opacity: '0.600' };
          break;
        case 'dj':
          this.categoryIconStyles = { opacity: '0.600' };
          break;
        // default:
        //   this.categoryIconStyles = { width: '40px', height: '40px' };
        //   break;
      }
      console.log('category', this.songData, data)
      this.totalcount = data.mostPlayedSongs.length;
      window.scroll(0, 0);
    });
  }
  private areArraysSameDeep(a: any[], b: any[]): boolean {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  playAllsongs(items, play) {
    debugger;
    let currentIndex = items.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (play == 'play') {
      if (!this.areArraysSameDeep(this._playerService.msaapPlaylist, items)) {
        this._playerService.msaapPlaylist = [];
        if (currentIndex != -1) {
          this.CheckSubscription(items, currentIndex);
        }
        else {
          this.CheckSubscription(items, 0);
        }
        this.isSongplay = true;
      }
      else if (this._playerService.msaapPlaylist == items) {
        this.playerService.playSongCallback(true);
      }
      else {
        this.playerService.playSongCallback(true);
      }
    }
    else {
      this.playerService.currentTime = this.player.nativeElement.currentTime;
      console.log('onpause', this._playerService.msaapPlaylist, items.length);
      this.isSongplay = false;
      this.playerService.playSongCallback(false);
    }
  }
  CheckSubscription(song, i) {
    debugger;
    if (song.length > 0) {
      this.playSong(song, i);
    }
    else {
      this.playSong(song, i);

    }
  }

  playSong(songs, index) {
    debugger;
    let currentIndex = songs.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (currentIndex === index && this.isplayed) {
      this.isplayed = false;
      this.playerService.playSongCallback(this.isplayed);
      return;
    }
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.isplayed = true;
    this.playerService.playSongCallback(this.isplayed);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }

}
