import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PlayerService } from 'src/app/Shared/player.service';
import { LoadScriptService } from 'src/app/loadScript.service';
import { Users } from 'src/app/models/users';
import { HomeService } from 'src/app/Shared/home.service';
import { SongsService } from 'src/app/Shared/songs.service';
import { Track } from 'Player/ngx-audio-player/src/public_api';
import { DatePipe } from '@angular/common';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';


@Component({
  selector: 'app-latest-ar-al-sng',
  templateUrl: './latest-ar-al-sng.component.html',
  styleUrls: ['./latest-ar-al-sng.component.css']
})
export class LatestArAlSngComponent implements OnInit {
  userDetails = new Users();
  UserId: any;
  title:any;
  topImage:any;
  topArtistId:any;
  topAlbumId:any;
  allSongs:any;
  name:any;
  songData: any;
  repeat=[];
  imgRepeat=[];
  totalcount: any=0;
  tfavsongs:any;
  IsActiveSongId: any;
  tfavlength: any;
  top10favSongs: any[];
  nextallfavSongs: any[];
  topNewArtists:any[];
  nextallNewArtists:any[];
  albumDetails:any;
  topNewAlbums:any[]=[];
  nextallNewAlbums:any[]=[];
  id:any;
  artistDetails:any[]=[];
  year:any
  isLoading: boolean;
  isplayed: boolean = false;

  constructor(private _songservice: SongsService, private router: Router,private route: ActivatedRoute,
    private loadScript: LoadScriptService, public playerService: AudioPlayerService,
    private _playerService: PlayerService,private homeService:HomeService,private datePipe: DatePipe) { 
     this.repeat.length=4;
     this.imgRepeat.length=3;
    }

  ngOnInit() {
    // window.scrollTo(0, 0);
    this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
    this.UserId = this.userDetails["userId"];
    this.id = this.route.snapshot.paramMap.get("id");
    this.playerService.getIsblogorCountryScreen().subscribe(value => {
      this.IsActiveSongId = value;
    });
    this.getmostplayedSongs();
    this.getTopNewArtistAlbumSongs();
  }
  getTopNewArtistAlbumSongs(){
    this.isLoading = true;
    this.homeService.getTopNewArtistAlbumSongs(this.UserId).subscribe((data:any)=>{debugger
      this.isLoading = false;
      console.log(data)
      if(this.id==1){
        this.title="Artists";
         this.artistDetails=data.newArtAlbSngs.artists;
         this.topArtistId=data.newArtAlbSngs.artists[0].artistId;
         this.topImage=data.newArtAlbSngs.artists[0].thumbImage;
         this.name=data.newArtAlbSngs.artists[0].fullName;
         this.year= this.datePipe.transform(data.newArtAlbSngs.artists[0].createdOn, 'yyyy');
         this.topNewArtists=data.newArtAlbSngs.artists.slice(1,11);
         this.nextallNewArtists=data.newArtAlbSngs.artists.slice(11,50);
         this.tfavlength=data.newArtAlbSngs.artists.length;
         console.log(this.topNewArtists,this.nextallNewArtists,this.title)
      }
      else if(this.id==2){debugger
        this.title="Albums";
        this.albumDetails=data.newArtAlbSngs.albums;
        this.topAlbumId=data.newArtAlbSngs.albums[0].albumId
        this.topImage=data.newArtAlbSngs.albums[0].thumbImage;
        this.name=data.newArtAlbSngs.albums[0].title;
        this.year=data.newArtAlbSngs.albums[0].releasedOn;
        this.topNewAlbums=data.newArtAlbSngs.albums.slice(1,11);
        this.nextallNewAlbums=data.newArtAlbSngs.albums.slice(11,50);
        this.tfavlength=data.newArtAlbSngs.albums.length;
      }
      else if(this.id==3){
        this.title="Songs";
        this.tfavsongs = data.newArtAlbSngs.songs;
        this.name=data.newArtAlbSngs.songs[0].title;
        this.year=data.newArtAlbSngs.songs[0].releasedOn;
        this.topImage=data.newArtAlbSngs.songs[0].thumbImage;
        this.allSongs=data.newArtAlbSngs.songs;
        this.top10favSongs = data.newArtAlbSngs.songs.slice(1,11);
        this.nextallfavSongs = data.newArtAlbSngs.songs.slice(11,50);
        this.tfavlength = data.newArtAlbSngs.songs.length;
        console.log(data.newArtAlbSngs.songs[0],this.top10favSongs,this.nextallfavSongs)
      }     
    })
  }
  getmostplayedSongs() {
    this.isLoading = true;
    window.scrollTo(0, 0);
    this._songservice.GetMostPlayedTop50songs(this.UserId).subscribe((data: any) => {
      this.isLoading = false;
      this.songData = data.mostPlayedSongs.slice(0,5);console.log(this.songData,this.songData[0].thumbImage)
      this.totalcount = data.mostPlayedSongs.length;
    })
  }
  Artistprofile(ArtistId: number) {
    this.router.navigate(['/newdiscover'], { queryParams: { Id: ArtistId } });
  }
  CheckSubscription(song, i) {
    debugger;
    if (song.length > 0) {
      this.playSong(song, i);
    }
    else {
      this.playSong(song, i);

    }
  }

  playSong(songs, index) {
    debugger;
    let currentIndex = songs.findIndex(x => x.songId == Number(localStorage.getItem('CurrentSongId')))
    if (currentIndex === index && this.isplayed) {
      this.isplayed = false;
      this.playerService.playSongCallback(this.isplayed);
      return;
    }
    this._playerService.msaapPlaylist = [];
    songs.forEach((song, i) => {
      const trackObj = new Track();
      trackObj.index = i;
      trackObj.image = song.thumbImage;
      trackObj.artistName = song.artistName;
      trackObj.link = song.streamUrl;
      trackObj.title = song.title;
      trackObj.songId = song.songId;
      this._playerService.msaapPlaylist.push(trackObj);
    });
    this.isplayed = true;
    this.playerService.playSongCallback(this.isplayed);

    if (this.playerService.repeatSong) {
      this.playerService.repeatSong = false;
    }
    this.playerService.setPlaylist(this._playerService.msaapPlaylist);
    this.playerService.selectATrack(index + 1);
    this._playerService.updateData(this._playerService.msaapPlaylist);
  }
  goto(title){
    if(title=="Artists"){
        this.Artistprofile(this.topArtistId);
    }
    else if(title=="Albums"){
      this.router.navigateByUrl("/discoverAlbum/"+this.topAlbumId)
    }
    else{
      this.CheckSubscription(this.allSongs , 0)
    }
  }
}
