import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private triggerMethodSubject = new Subject<void>();
  private triggerMethodLikeFavoriteSubject= new Subject<void>();

  triggerMethod$ = this.triggerMethodSubject.asObservable();
  triggerMethodsLikeFavorite$= this.triggerMethodLikeFavoriteSubject.asObservable();
  constructor() { }

  triggerMethod() {
    this.triggerMethodSubject.next();
  }

  triggerMethodLikeFavorite(){
    this.triggerMethodLikeFavoriteSubject.next();
  }

  
}
