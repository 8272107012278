import { Component, OnInit, Input, ViewChild, DebugNode, ElementRef, Output, EventEmitter, OnDestroy, TemplateRef } from '@angular/core';
import { AudioPlayerService } from '../../service/audio-player-service/audio-player.service';

import { Track } from '../../model/track.model';
import { BaseAudioPlayerFunctions } from '../base/base-audio-player-components';
import { HomeService } from '../../../../../../src/app/Shared/home.service';
import { UserService } from '../../../../../../src/app/Shared/user.service';

import { Playlist } from '../../../../../../src/app/models/playlist';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TooltipModule } from 'ng2-tooltip-directive';

import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgForm } from '@angular/forms';
import { finalize, map, take, takeUntil } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlider } from '@angular/material/slider';
import { MatTableDataSource } from '@angular/material/table';
import { PlayerService } from 'src/app/Shared/player.service';
import { Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'mat-advanced-audio-player',
    templateUrl: './mat-advanced-audio-player.component.html',
    styleUrls: ['./mat-advanced-audio-player.component.css']
})
export class MatAdvancedAudioPlayerComponent extends BaseAudioPlayerFunctions implements OnInit {
    @ViewChild('closeBtn') closeBtn: ElementRef;
    @ViewChild('f') form: NgForm;
    displayedColumns: string[] = ['title', 'status'];
    timeLineDuration: MatSlider;
    imgSrc: string = "../../../../../../assets/images/Player-icon/play-button-nor.png";
    imgSrcPrev: string = "../../../../../../assets/images/Player-icon/previous.png";
    imgSrcNext: string = "../../../../../../assets/images/Player-icon/next.png";
    imgSrcTenSecForward: string = "../../../../../../assets/images/Player-icon/next-song.png";
    imgSrcTenSecReverse: string = "../../../../../../assets/images/Player-icon/prev-song.png";
    imgSrcPlayRandonSong: string = "../../../../../../assets/images/Player-icon/random-song.png";
    imgSrcPlayRandonSongActive: string = "../../../../../../assets/images/Player-icon/random-song-act.png";
    dataSource = new MatTableDataSource<Track>();
    mute1: boolean = false;
    repeat: boolean = false;
    paginator: MatPaginator;
    shufflePlayList: boolean = false;
    tempSongList = [];
    playlistData: Track[];
    playlist1 = new Playlist();
    currentSongId;
    createchecked: boolean = true;
    updateextchecked: boolean;
    volume: any = 100;
    TransList: Array<any> = [];
    currentvolume: any = 1;
    @Input()
    displayTitle = true;

    @Input()
    displayPlaylist = true;

    @Input()
    pageSizeOptions = [10, 20, 30];

    @Input()
    expanded = true;

    playlistTrack: any = [];
    userDetails: any;
    playListDetails: any;
    songid = [];
    playd = [];
    index;
    indexSong: number = 0;
    disableShuffle: boolean = false;
    songIndexForSlider: number;
    songIsthere: boolean = false;
    substatus: any;
    PlaylistDetails: any;
    songidd = [];
    CurentPlaysong: any;
    songexist: boolean = false;
    userId: any
    paramId: any;
    allsongs: any;
    onchangeplaylist: any;
    Image = '';
    tempIndex: number = 0;
    showSongDetail: boolean = false;
    //tempSrc = this.playlistService.playlist[this.playlistService.indexSong].image;
    @Output()
    movieSelectedEventEmitter = new EventEmitter();
    values: any;
    data: any;
    busyInitialLoading: boolean = false;
    private destroy$: Subject<void> = new Subject<void>();
    private hasCalledApi = false;
    userPlaylistId: any;
    dialogRef: MatDialogRef<any> | null = null;

    constructor(public playlistService: AudioPlayerService,
        private route: ActivatedRoute,
        private homeService: HomeService,
        private playerService: AudioPlayerService,
        private toastr: ToastrService, public userService: UserService,
        private router: Router,
        public translate: TranslateService, private dialog: MatDialog) {
        super();

        // this.playerService.playSongEvent.subscribe((song) => {
        //     this.onPlay(song);
        //     console.log('Test');
        //   });
        // this.playerService.registerPlaySongCallback((song) =>{
        //     debugger;
        //     this.onPlay(song);
        // } 
        // );
        this.playerService.registerPlaySongCallback((song) => {
            debugger;
            if (song == true) {
                //this.loaderDisplay = false;
                if(this.userDetails.subscriptionStatus=="True")
                    {
                setTimeout(() => {
                    this.loaderDisplay = false;
                    this.playBtnHandler();
                    this.playPause();
                }, 2000);

                // this.playBtnHandler();
                // this.playPause();
            }
            else{
                this.openDialog();
            }
            }
            else {
                this.loaderDisplay = false;
                //this.player.nativeElement.paused = song;
                var mediaElement = (document.getElementById("audioPlayer") as HTMLMediaElement);
                if (!mediaElement.paused) {
                    mediaElement.pause();
                }
                // this.playBtnHandler();
                this.playPause();
            }
            // this.onPlay(song);
        }
        );
        this.playerService.registerPlaySongCallbackmain((song) => {
            if (song == true) {
                if(this.userDetails.subscriptionStatus=="True")
                    {
                setTimeout(() => {
                    this.CheckSubscription();
                }, 2000);
            }
            }
        });

        this.playerService.registerPlaylistCallbackmain((playlistid, isplaylist) => {
            this.playlistService.userPlaylistId = playlistid;
            this.playlistService.isUserPlaylist = isplaylist;
            console.log('plId', this.userPlaylistId, isplaylist);
        });
        // this.subscriptional = this.playerService.playSong$.pipe(take(1)) .subscribe({
        //     next: (song) => {              
        //       if(song == true){
        //         this.CheckSubscription();
        //         this.subscriptional.unsubscribe();
        //         song = false;
        //         return;
        //         //song = 'false';
        //       }
        //     }
        //   });
    }


    ngOnInit() {
        this.player.nativeElement.volume = 1;
        this.playlistService.setPlaylist(this.playlistData);
        this.busyInitialLoading = true;
        this.playlistService.getSubjectCurrentTrack().pipe(takeUntil(this.destroy$),
            finalize(() => {
                // this.spinner.hide(); // Hide spinner after response or error
                this.busyInitialLoading = false;
            })).subscribe((playlistTrack: any) => {
                if (playlistTrack != undefined) {
                    let bool;
                    // for (let a = 0; a < playlistTrack.length; a++) {
                    //     if (playlistTrack[a].index != 0) {
                    //         bool = false;
                    //     } else {
                    //         bool = true;
                    //     }
                    // }
                    bool = true;
                    playlistTrack.forEach(element => {
                        if (element.index != 0) {
                            bool = false;
                        }
                    });
                    if (bool) {
                        this.player.nativeElement.currentTime = 0;
                    }
                    else {
                        this.player.nativeElement.pause(this.player.nativeElement.currentTime);
                    }
                    this.playlistTrack = playlistTrack;
                }
            });

        this.player.nativeElement.currentTime = 0;
        this.playlistService.init();

        this.paramId = this.route.snapshot.queryParams["Id"];
        if (this.playListDetails == undefined) {
            this.freshplaylist();
        }
        this.substatus = this.userDetails.subscriptionStatus;
        this.setDataSourceAttributes();
        this.bindPlayerEvent();
        this.player.nativeElement.addEventListener('ended', () => {
            if (this.checkIfSongHasStartedSinceAtleastTwoSeconds()) {
                this.nextSong();
            }
        });
        if(this.playerService.duration){
            this.player.nativeElement.currentTime = this.playerService.duration;
        }
        // this.playlistService.getIssongplay().subscribe(value => {
        //     debugger;
        //     let isSongPlaying = localStorage.getItem('IsSongPlaying');

        //     if (isSongPlaying == 'false' && value == true) {
        //         console.log(value);
        //         this.CheckSubscription();
        //     }
        //     else {
        //         this.CheckSubscription();
        //     }
        // });

        // this.playlistService.currentData.pipe(take(1)).subscribe(data => {
        //     this.data = data;
        //     console.log(this.data)
        //   });

        //  this.values = this.playlistService.playEventEmitter;
        //  console.log(this.values);
        // // }
        // .subscribe((event) => {
        //     // if (value == true) {
        //     //     this.playSongs();
        //     //     console.log(value);
        //     // }
        //     console.log(event);
        // });


    }

    onPlay(song: any) {
        debugger; // Adjust to your song's data type
        // Logic to play the song
        console.log('onplay', song)
        if (song == true) {
            this.songIsthere = true;
            this.playBtnHandler();
            this.playPause();
            //this.CheckSubscription();  
        }
        //this.CheckSubscription();
    }

    //    ngOnDestroy() {
    //     // Always good practice to unsubscribe
    //     this.subscription.unsubscribe();
    //   }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }


    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    freshplaylist() {
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.playlist1.UserId = this.userDetails.userId;
        this.homeService.getPlaylist(this.playlist1.UserId).subscribe((data: any) => {
            this.playListDetails = data.playListDetails;
            this.paramId = this.route.snapshot.queryParams["Id"];
            let playlist = data.playListDetails.filter(x => x.playlistId == this.paramId);
            this.homeService.allPlayList.next(this.playListDetails);

            // this.homeService.playlist(this.paramId, this.playlist1.UserId).subscribe((Response: any) => {
            //     let songs = playlist[0].songs;
            //     songs = Response.playlistDetails;
            //     this.homeService.playListSongs.next(songs);
            // })

        })
    }
    refreshplaylistdetails() {
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.userId = this.userDetails.userId;
        this.paramId = this.route.snapshot.queryParams["Id"];
        this.homeService.playlist(this.paramId, this.userId).subscribe(Response => {
            this.allsongs = Response;
            this.TransList.push(Response);
            this.allsongs = this.TransList[0];
            // this._homeService.playlist(this.playListDetails.playlistId,this.playlist1.UserId);
        })
    }

    setDataSourceAttributes() {

        let index = 0;
        if (this.playlistData) {
            this.playlistData.forEach(data => {
                data.index = index++;
            });
            this.dataSource = new MatTableDataSource<Track>(this.playlistData);
            this.dataSource.paginator = this.paginator;

        }

    }

    nextSong(): void {
        this.currentTime = 0;
        this.duration = 0.01;
        this.playlistService.nextSong();
        //Check if shuffle is enable or not
        if (this.playlistService.shufflePlayList) {
            this.playlistService.playPauseBtn = [];
            let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId')) + 1;
            if (currentIndex + 1 > this.playlistService.playlist.length) {
                currentIndex = 0;
            }
            if (currentIndex != -1) {
                let songId = this.playlistService.playlist[currentIndex].songId;
                let songIndex = this.homeService.playListArray.findIndex(x => x.songId == songId);

                localStorage.setItem('CurrentSongId', songId);
                if (this.playlistService.playPauseBtn[songIndex]) {
                    this.playlistService.playPauseBtn[songIndex] = false;
                    localStorage.setItem('IsSongPlaying', 'false');
                    localStorage.setItem('IsPlaying', 'false');
                } else {
                    this.playlistService.playPauseBtn[songIndex] = true;
                    localStorage.setItem('IsSongPlaying', 'true');
                    localStorage.setItem('IsPlaying', 'false');
                }
            }
        } else {
            if (this.playlistService.playlist.length != 1) {
                this.playlistService.playPauseBtn = [];
                let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
                if (currentIndex != -1) {
                    if (this.playlistService.playPauseBtn[this.playlistService.indexSong]) {
                        this.playlistService.playPauseBtn[this.playlistService.indexSong] = false;
                        localStorage.setItem('IsSongPlaying', 'false');
                        localStorage.setItem('IsPlaying', 'false');
                    } else {
                        this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
                        localStorage.setItem('IsSongPlaying', 'true');
                        localStorage.setItem('IsPlaying', 'false');
                    }
                }
            }
        }

        this.songIndexForSlider = this.playlistService.indexSong;
        this.changeSlider(this.songIndexForSlider);
        this.play();
        this.hasCalledApi = false;
    };
    playPause() {
        debugger;
        if (this.playlistService.playlist.length != 1) {
            console.log(this.playlistTrack, this.playlistService.playPauseBtn, this.playlistService.indexSong)
            let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
            console.log(currentIndex);
            if (currentIndex != -1) {
                if (this.playlistService.playPauseBtn[this.playlistService.indexSong]) {
                    this.playlistService.playPauseBtn[this.playlistService.indexSong] = false;
                    localStorage.setItem('IsSongPlaying', 'false');
                    localStorage.setItem('IsPlaying', 'false');
                } else {
                    this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
                    localStorage.setItem('IsSongPlaying', 'true');
                    localStorage.setItem('IsPlaying', 'false');
                    // if(this.currentTime==60){
                    // this.playerService.updatecount(this.playlistTrack[1].songId);
                    // }
                }
            }
        } else {
            let currentIndex = this.playlistService.playPauseBtn.findIndex(x => x == true);

            if (this.playlistService.playPauseBtn[currentIndex]) {
                this.playlistService.playPauseBtn[currentIndex] = false;
                this.tempIndex = currentIndex;
                localStorage.setItem('IsSongPlaying', 'false');
                localStorage.setItem('IsPlaying', 'false');
            } else {
                this.playlistService.playPauseBtn[this.tempIndex] = true;
                localStorage.setItem('IsSongPlaying', 'true');
                localStorage.setItem('IsPlaying', 'false');
                // if(this.currentTime==60){
                // this.playerService.updatecount(this.playlistTrack[1].songId);
                // }
            }

            let Cursong = localStorage.getItem('IsSongPlaying');
            if (Cursong == 'true') {
                localStorage.setItem('IsSongPlaying', 'false');
            } else {
                localStorage.setItem('IsSongPlaying', 'true');
            }
            let CurPlayingsong = localStorage.getItem('IsPlaying');
            if (CurPlayingsong == 'true') {
                localStorage.setItem('IsPlaying', 'false');
            } else {
                localStorage.setItem('IsPlaying', 'true');
            }
        }


    }

    previousSong(): void {
        this.currentTime = 0;
        this.duration = 0.01;
        this.playlistService.previousSong();
        this.playlistService.playPauseBtn = [];
        // this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
        let currentIndex = this.playlistService.playlist.findIndex(x => x.songId == localStorage.getItem('CurrentSongId'))
        if (currentIndex != -1) {
            if (this.playlistService.playPauseBtn[this.playlistService.indexSong]) {
                this.playlistService.playPauseBtn[this.playlistService.indexSong] = false;
                localStorage.setItem('IsSongPlaying', 'false');
                localStorage.setItem('IsPlaying', 'false');
            } else {
                this.playlistService.playPauseBtn[this.playlistService.indexSong] = true;
                localStorage.setItem('IsSongPlaying', 'true');
                localStorage.setItem('IsPlaying', 'false');
            }
        }
        this.songIndexForSlider = this.playlistService.indexSong;
        this.changeSlider(this.songIndexForSlider);
        this.play();
        this.hasCalledApi = false;
    };

    resetSong(): void {
        if (this.playlistTrack.length != 0) {
            this.player.nativeElement.src = this.playlistTrack[1].link;
        }

    };

    subcheckpreviousSong() {
        // this.songIsthere = true;
        // this.playBtnHandler();

        // this.previousSong();
        // debugger;
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        if (this.userDetails.subscriptionStatus == "False") {
            this.openDialog();
            // alert("Your Subscription is End");
            // this.router.navigate(['/account/upgradeplan']);
            //document.getElementById("SubcheckForm").style.display = "block";
            // this.playlistService.playPauseBtn[this.playlistService.indexSong]=false;
            // localStorage.setItem('IsSongPlaying','false');

        }
        else {
            this.songIsthere = true;
            this.playBtnHandler();

            this.previousSong();
        }

    }
    checksubnextSong() {
        
        if (this.userDetails.subscriptionStatus == "False") {
            this.openDialog();
        // alert("Your Subscription is End");
        // this.router.navigate(['/account/upgradeplan']);
        //  document.getElementById("SubcheckForm").style.display = "block";
        // this.playlistService.playPauseBtn[this.playlistService.indexSong]=false;
        // localStorage.setItem('IsSongPlaying','false');

        }
        else {
        this.songIsthere = true;
        this.playBtnHandler();

        this.nextSong();
        }

    }
    //Reverse a song 10 sec 
    seekReverse() {
        this.player.nativeElement.currentTime = this.player.nativeElement.currentTime - 10;
    }

    //Forward a song 10 sec
    seekForward() {
        this.player.nativeElement.currentTime = this.player.nativeElement.currentTime + 10;
    }

    //Shuffle Playlist records
    shuffle() {
        this.playlistService.shufflePlayList = !this.playlistService.shufflePlayList;
        if (this.playlistService.playlist.length > 1) {
            //Is Shuffle clicked
            if (this.playlistService.shufflePlayList) {
                this.playlistData = this.playlistService.playlist.slice(0);//Copy the current array to playlist data

                this.homeService.playListArray = this.playlistService.playlist.slice(0);//Copy the current array to playlist data

                //Get the current index of song
                let findCurrentIndex = this.homeService.playListArray.findIndex(x => x.songId == this.playlistService.currentId)
                var currentIndex = this.playlistData.length, temporaryValue, randomIndex;


                // While there remain elements to shuffle...
                while (0 !== currentIndex) {
                    // Pick a remaining element...
                    randomIndex = Math.floor(Math.random() * currentIndex);
                    currentIndex -= 1;
                    // And swap it with the current element.
                    temporaryValue = this.playlistData[currentIndex];
                    this.playlistData[currentIndex] = this.playlistData[randomIndex];
                    this.playlistData[randomIndex] = temporaryValue;
                }
                for (let a = 0; a < this.playlistData.length; a++) {
                    this.playlistData[a].index = a;
                }

                this.playlistService.shufflePlayList = true;
                //Current Index of songs after shuffle
                let CurrSongIndex = this.playlistData.findIndex(x => x.songId == this.playlistService.currentId);
                let CurrSong = this.playlistData.find(x => x.songId == this.playlistService.currentId);
                //Keep the index as it for the current song
                let temObj = this.playlistData[findCurrentIndex];

                this.playlistData[findCurrentIndex] = CurrSong;
                this.playlistData[CurrSongIndex] = temObj;

                this.playlistService.playlist = this.playlistData;
                this.playlistService.shuffleSongs = this.playlistData;
                this.playlistService.playlistForPlayer.next(this.playlistData);

                return this.playlistService.playlist;
            } else {

                this.playlistService.shufflePlayList = false;
                //ShuffledArrayList
                let shuffleIndex = this.playlistService.playlist.findIndex(x => x.songId == this.playlistService.currentId);
                let shuffleSong = this.playlistService.playlist.find(x => x.songId == this.playlistService.currentId);

                //OriginalArrayList
                let SongIndex = this.homeService.playListArray.findIndex(x => x.songId == this.playlistService.currentId);
                let Song = this.homeService.playListArray.find(x => x.songId == this.playlistService.currentId);

                // this.playlistService.playlist = this.homeService.playListArray.slice(0);

                // let temObj = this.playlistService.playlist[shuffleIndex];

                // this.playlistData[shuffleIndex] = shuffleSong;
                // this.playlistData[SongIndex] = temObj;
                let temShuffleindex = 0;
                let shuffleIndexZero = 0;
                //Keep the index as it is and push the same playlist as there before shuffle
                for (let a = 0; a < this.playlistService.playlist.length; a++) {
                    this.playlistService.playlist[a].index = a;
                    if (shuffleIndex + 1 != this.playlistService.playlist.length) {
                        if (this.homeService.playListArray[SongIndex + 1] != undefined) {
                            if (SongIndex != this.playlistService.playlist.length) {
                                this.playlistData[shuffleIndex + 1] = this.homeService.playListArray[SongIndex + 1];
                                shuffleIndex++;
                                SongIndex++;
                            }
                        } else {
                            this.playlistData[shuffleIndex + 1] = this.homeService.playListArray[temShuffleindex];
                            temShuffleindex++;
                            shuffleIndex++;
                        }
                    } else {
                        if (shuffleIndex + 1 == this.playlistService.playlist.length && this.homeService.playListArray[SongIndex + 1] == undefined) {
                            break;
                        } else if (shuffleIndex + 1 == this.playlistService.playlist.length || this.homeService.playListArray[SongIndex + 1] != undefined) {
                            this.playlistData[shuffleIndexZero] = this.homeService.playListArray[SongIndex + 1];
                            shuffleIndexZero++;
                            SongIndex++;
                        }
                        else if (SongIndex + 1 == this.playlistService.playlist.length) {
                            break;
                        }

                    }
                    // if (temShuffleindex != 0) {
                    //     this.playlistData[temShuffleindex - 1] = this.homeService.playListArray[temShuffleindex - 1];
                    //     temShuffleindex--;
                    // }
                }
                // for (let a = shuffleIndex + 1; a < this.playlistService.playlist.length; a++) {
                //     if (a == this.playlistService.playlist.length) {
                //         shuffleIndex = 0;
                //         this.playlistData[shuffleIndex] = this.homeService.playListArray[shuffleIndex];
                //     } else {
                //         this.playlistService.playlist[a].index = a;
                //         this.playlistData[a] = this.homeService.playListArray[a];
                //     }
                // }
                this.playlistService.shuffleSongs = this.playlistService.playlist.slice(0);
                this.playlistService.playlistForPlayer.next(this.playlistService.playlist);
                return this.playlistService.playlist;
            }
        } else {
            this.disableShuffle = true;
        }
    }

    selectTrack(index: number): void {
        this.playlistService.selectATrack(index);
        //Check if Shuffle is active or not
        setTimeout(() => {
            this.player.nativeElement.play();
        }, 0);
    };

    checkIfSongHasStartedSinceAtleastTwoSeconds(): boolean {
        return this.player.nativeElement.currentTime > 2;
    };

    @Input()
    set playlist(playlist: Track[]) {

        this.playlistData = playlist;
        this.ngOnInit();
    }

    repeatSong() {
        this.repeat = !this.repeat;
        this.playlistService.playSingleSong();
    }

    onMouseOver(): void {

        this.imgSrc = "../../../../../../assets/images/Player-icon/play-button.png";
    }
    onMouseOut(): void {
        this.imgSrc = "../../../../../../assets/images/Player-icon/play-button-nor.png";
    }
    onMouseOverPrev(): void {

        this.imgSrcPrev = "../../../../../../assets/images/Player-icon/previous-act.png";
    }
    onMouseOutPrev(): void {
        this.imgSrcPrev = "../../../../../../assets/images/Player-icon/previous.png";
    }
    onMouseOverNext(): void {

        this.imgSrcNext = "../../../../../../assets/images/Player-icon/next-act.png";
    }
    onMouseOutNext(): void {
        this.imgSrcNext = "../../../../../../assets/images/Player-icon/next.png";
    }
    onMouseOverReverse(): void {
        this.imgSrcNext = "../../../../../../assets/images/Player-icon/prev-song-act.png";
    }
    onMouseOverForward(): void {
        this.imgSrcNext = "../../../../../../assets/images/Player-icon/next-song-act.png";
    }
    onMouseOutReverse(): void {
        this.imgSrcNext = "../../../../../../assets/images/Player-icon/prev-song.png";
    }
    onMouseOutForward(): void {
        this.imgSrcNext = "../../../../../../assets/images/Player-icon/next-song.png";
    }

    currTimePosChanged1(event) {
        this.mute1 = false;
        this.player.nativeElement.muted = false;
        var volumeVal = (event.value) / 100;
        this.currentvolume = volumeVal

        if (volumeVal <= 1)
            this.player.nativeElement.volume = volumeVal;
    }

    muteAudio() {
        this.mute1 = !this.mute1;
        this.player.nativeElement.muted = !this.player.nativeElement.muted;
        if (this.mute1) {
            this.volume = this.volume - 100
        }
        else if (!this.mute1) {
            this.volume = this.currentvolume * 100;
        }
    }

    onSubmit(f) {
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        this.playlist1.UserId = this.userDetails.userId;
        this.playlist1.SongId = this.playlistService.getcurrentsongid;

        this.homeService.addOrUpdateplaylist(this.playlist1).subscribe((res: any) => {
            if (res.status == 200) {
                this.freshplaylist()
                //this.closeForm();
                this.closeBtn.nativeElement.click();
                this.playlist1.PlaylistId = undefined;
                this.updateextchecked = false;
                this.createchecked = true;
                f.submitted = false;
                this.toastr.success("Playlist updated successfully");
                this.form.resetForm();
            }
            else {
                this.freshplaylist();
                this.toastr.success("Playlist Already Exists");
            }
        })
    }
    fetchSongId() {

        this.playlistService.getcurrentsongid;
    }
    checked() {
        this.createchecked = true;
        this.updateextchecked = false;
        this.playlist1.Name = '';
    }
    updatechecked() {
        this.updateextchecked = true;
        this.createchecked = false;
        this.playlist1.PlaylistId = undefined;
    }
    openForm() {
        document.getElementById("myFormsong").style.display = "block";
    }
    closeForm() {
        document.getElementById("myFormsong").style.display = "none";
    }
    refreshplaylist() {
        this.playlist1.Name = '';
        this.homeService.getPlaylist(this.userDetails.userId).subscribe((data: any) => {
            this.playListDetails = data.playListDetails;
            if (this.playListDetails.length >= 10) {
                for (var i = this.index; i < 10; i++) {
                    this.playd[i] = this.playListDetails[i];
                    this.homeService.playId[i] = this.playListDetails[i];
                }
            } else {
                for (var i = this.index; i < this.playListDetails.length; i++) {
                    this.playd[i] = this.playListDetails[i];
                    this.homeService.playId[i] = this.playListDetails[i];
                }
            }
        });
    }

    showSlider(songData, index) {
        debugger;
        var obj = {
            "songid": songData,
            "isSongPage": true
        }
        this.movieSelectedEventEmitter.emit(obj);
    }
    changeSlider(index) {
        let Url = window.location.pathname;
        if (Url.includes('songsdetails')) {
            this.router.navigate(['/album/songsdetails'], { queryParams: { Id: index } });
        }
    }
    CheckSubshuffle() {
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        if (this.userDetails.subscriptionStatus == "False") {
            this.openDialog();
            // alert("Your Subscription is End");
            // this.router.navigate(['/account/upgradeplan']);
            document.getElementById("SubcheckForm").style.display = "block";
            // this.playlistService.playPauseBtn[this.playlistService.indexSong]=false;
            // localStorage.setItem('IsSongPlaying','false');

        }
        else {
            this.songIsthere = true;
            //this.playBtnHandler();
            this.shuffle();
            //this.previousSong();
        }
    }

    @ViewChild('SubcheckForm') SubcheckForm!: ElementRef;
    CheckSubscription() {
        debugger
        this.userDetails = JSON.parse(localStorage.getItem('UserDetails'));
        // this.songIsthere = true;
        // this.playBtnHandler();
        // this.playPause();
        if (this.userDetails.subscriptionStatus == "False") {
            // alert("Your Subscription is End");
            // this.router.navigate(['/account/upgradeplan']);
            //document.getElementById("SubcheckForm").style.display = "block";
            // this.playlistService.playPauseBtn[this.playlistService.indexSong]=false;
            // localStorage.setItem('IsSongPlaying','false');

            // const modalElement: HTMLElement = this.SubcheckForm.nativeElement;
            // modalElement.classList.add('show'); // Add show class
            // modalElement.style.display = 'block'; // Set display to block
            // (window as any).$('body').addClass('modal-open');

            this.openDialog();

        }
        else {
            this.songIsthere = true;
            this.playBtnHandler();
            this.playPause();
        }
    }
    closeSubcheckForm() {
        document.getElementById("SubcheckForm").style.display = "none";
        this.router.navigate(['/account/upgradeplan']);
    }

    // playSongs() {
    //     this.CheckSubscription();
    // }

    ngAfterViewInit(): void {
        super.ngAfterViewInit(); // This calls Component B's ngAfterViewInit

        // You can add more listeners, modify properties, etc.
        // For example, listen to another event:
        this.player.nativeElement.addEventListener('playing', () => {
            debugger;
            this.isPlaying = true;
            this.duration = Math.floor(this.player.nativeElement.duration);
            this.playerService.playSongCallbackno(this.isPlaying);
            console.log('hi I\'m playing3', this.isPlaying , this.duration);
        });
        this.player.nativeElement.addEventListener('pause', () => {
            this.isPlaying = false;
            this.playerService.playSongCallbackno(this.isPlaying);
            console.log('hi I\'m paused2', this.isPlaying);
        });
        this.player.nativeElement.addEventListener('timeupdate', () => {
            this.currentTime = Math.floor(this.player.nativeElement.currentTime);
            localStorage.setItem('songDuration', this.currentTime.toString());
            console.log(this.currentTime)
            if (this.currentTime === 60 && !this.hasCalledApi) {
                debugger;
                this.hasCalledApi = true;
                this.playerService.updatecount(this.playlistService.getcurrentsongid);
                debugger;
                if (this.playlistService.userPlaylistId) {
                    this.playerService.updatePlaylistplaycount(this.playlistService.userPlaylistId, this.playlistService.getcurrentsongid, this.userDetails.userId, this.playlistService.isUserPlaylist)
                }
            }
            if (this.currentTime === 0) {
                this.hasCalledApi = false;
            }

        });
    }

    // playlistcount() {
    //     console.log('test' ,  this.playlistService.userPlaylistId);
    //         this.playerService.updatePlaylistplaycount(this.playlistService.userPlaylistId, this.playlistService.getcurrentsongid)
    // }

    closePlaylistModal() {
        const modalElement: HTMLElement = this.SubcheckForm.nativeElement;
        (window as any).$(`#${modalElement.id}`).modal('hide');
        modalElement.style.display = 'none'; // Hide the modal
        setTimeout(() => {
            (window as any).$('body').removeClass('modal-open'); // Remove 'modal-open' class
            (window as any).$('.modal-backdrop').remove(); // Remove modal backdrop
        }, 1000);
    }

    @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;

    openDialog(): void {
        this.dialogRef = this.dialog.open(this.dialogTemplate, {
            width: '500px',
            panelClass: 'custom-dialog',
            disableClose:true
        });
        //this.dialogRef.disableClose = true;
    }

    closeDialog(): void {
        debugger
        if (this.dialogRef) {
            this.dialogRef.close();
            this.router.navigate(['/account/upgradesubscription']);
        }
    }

}