import { Injectable } from '@angular/core';
import { ConfigService } from '../app.config';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpModule } from '@angular/http';
@Injectable()

export class LoginService {
    private url = '';
    private baseUrl = '';

    private subscriptionName = new BehaviorSubject<string | null>('');
    SubscriptionName = this.subscriptionName.asObservable();

    constructor(private http: HttpClient, private configService: ConfigService) {
        this.baseUrl = configService.getServiceBaseUrl();
    }
    //User Login
    userLogin(emailId, password) {
        this.url = this.baseUrl + 'api/Users/UserLogin?Email=' + emailId + '&Password=' + password;
        return this.http.get(this.url);
    }

    //Artist Login
    artistLogin(emailId, password) {
        this.url = this.baseUrl + 'api/Artists/ArtistLogin?Email=' + emailId + '&Password=' + password;
        return this.http.get(this.url);
    }

    userRegistration(data) {
        this.url = this.baseUrl + 'api/Users/AddUpdateUser';
        return this.http.post(this.url, data)
    }

    //Get All List of Countires
    getAllCountries() {
        this.url = this.baseUrl + 'api/Countries/GetAllCountry';
        return this.http.get(this.url);
    }

    //Add Suggestion
    addSuggestion(data) {
        this.url = this.baseUrl + 'api/Users/AddSuggestion';
        return this.http.post(this.url, data)
    }
    //Add Problems
    addProblem(data) {
        this.url = this.baseUrl + 'api/Users/AddProblem';
        return this.http.post(this.url, data)
    }
    changepassword(UserId, OldPassword, NewPassword, ConfirmPassword) {
        this.url = this.baseUrl + 'api/Users/ChangePassword?UserId=' + UserId + '&OldPassword=' + OldPassword + '&NewPassword=' + NewPassword + '&ConfirmPassword=' + ConfirmPassword;
        return this.http.post(this.url, UserId);
    }

    artistchangepassword(ArtistId, OldPassword, NewPassword, ConfirmPassword) {
        this.url = this.baseUrl + 'api/Artists/ArtistChangePassword?ArtistId=' + ArtistId + '&OldPassword=' + OldPassword + '&NewPassword=' + NewPassword + '&ConfirmPassword=' + ConfirmPassword;
        return this.http.post(this.url, ArtistId);
    }
    resetpassword(emailId) {
        this.url = this.baseUrl + 'api/Users/ForgetPassword?Email=' + emailId;
        //return this.http.post(this.url, emailId)
        return this.http.get(this.url);
    }
    forgetpassword(UserId, Password, ConfirmPassword) {
        this.url = this.baseUrl + 'api/Users/ResetPassword?UserId=' + UserId + '&Password=' + Password + '&ConfirmPassword=' + ConfirmPassword;
        return this.http.post(this.url, UserId);
    }

    artistresetpassword(emailId) {
        this.url = this.baseUrl + 'api/Artists/ForgetPassword?Email=' + emailId;
        return this.http.get(this.url);
    }

    artistforgetpassword(ArtistId, Password, ConfirmPassword) {
        this.url = this.baseUrl + 'api/Artists/ArtistResetPassword?ArtistId=' + ArtistId + '&Password=' + Password + '&ConfirmPassword=' + ConfirmPassword;
        return this.http.post(this.url, ArtistId);
    }

    getalluseremail() {
        this.url = this.baseUrl + 'api/Users/GetAllUserEmail';
        return this.http.get(this.url);
    }
    getUserOldPassword(UserId, IsMobileReq) {

        this.url = this.baseUrl + 'api/Users/GetUserOldPassword?UserId=' + UserId + '&IsMobileReq=' + IsMobileReq;
        return this.http.get(this.url);
    }
    CheckMultidevices(UserId, sessionId) {
        this.url = this.baseUrl + 'api/Users/isValidSession?UserId=' + UserId + '&sessionId=' + sessionId;
        return this.http.get(this.url);
    }
    getallartistemail() {
        this.url = this.baseUrl + 'api/Artists/GetArtists';
        return this.http.get(this.url);
    }
    gettopthree() {
        this.url = this.baseUrl + 'api/TobyHomes/GetrtSection';
        return this.http.get(this.url);
    }
    GetWtComingUp() {
        this.url = this.baseUrl + 'api/TobyHomes/GetWtComingUp';
        return this.http.get(this.url);
    }
    GetPopularNow() {
        this.url = this.baseUrl + 'api/TobyHomes/GetPopularNow';
        return this.http.get(this.url);
    }
    postData(credentials) {
        this.url = this.baseUrl + 'api/Users/AddUpdateUser';
        return this.http.post(this.url, credentials);
    }
    Getfavsongs(userid, songid) {
        this.url = this.baseUrl + 'api/Songs/GetFavouriteSong?UserId=' + userid + '&SongId=' + songid;
        return this.http.get(this.url);
    }
    paypalsubscription(usersubscription) {
        this.url = this.baseUrl + 'api/PayPal/CreatePayPalSubscription';
        return this.http.post(this.url, usersubscription);
    }
    gettopfive() {
        this.url = this.baseUrl + 'api/Artists/GetrtSection';
        return this.http.get(this.url);
    }
    logOutAudit(UserId, sessionId) {
        this.url = this.baseUrl + 'api/Users/logOutUser?UserId=' + UserId + '&sessionId=' + sessionId;
        return this.http.post(this.url, UserId);
    }

    GetInvitedUserById(inviteId) {
        this.url = this.baseUrl + 'api/Users/GetInvitedUserById?inviteId=' + inviteId;
        return this.http.get(this.url);
    }

    invitedUserRegistration(data) {
        this.url = this.baseUrl + 'api/Users/invitedUserRegistration';
        return this.http.post(this.url, data)
    }

    change(e: string) {
        this.subscriptionName.next(e);
    }

    getAllFaqs() {
        this.url = this.baseUrl + 'api/TobyHomes/GetAllFAQs ';
        return this.http.get(this.url)
    }

    
    userMemberRegistration(data) {
        this.url = this.baseUrl + 'api/Users/userMemberRegistration';
        return this.http.post(this.url, data)
    }

    VerifyEmail(email){
        this.url = this.baseUrl + 'api/Users/VerifyEmail?email=' + email;
        return this.http.post(this.url,email)
    }

}
