//Modules
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TobyHomeScreenModule } from './toby-home-screen/toby-home-screen.module'
import { AccountModule } from '../app/account/account.module';
import { LoginModule } from './login/login.module';
import { HttpClientModule } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { AppRoutingModule } from '../app/app-routing.module';
import { library } from '@fortawesome/fontawesome-svg-core';
//Components
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoadScriptService } from './loadScript.service';
import { LoginComponent } from './login/login.component';
import { DiscoverComponent } from './discover/discover.component';
import { TobyHomeScreenComponent } from './toby-home-screen/toby-home-screen.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { RightsideMenuComponent } from './layout/rightside-menu/rightside-menu.component';
import { LeftsideMenuComponent } from './layout/leftside-menu/leftside-menu.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './error-interceptor';
import { LoginService } from './Shared/login.service';
import { ConfigService } from '../app/app.config';
import { HomeService } from './Shared/home.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SongsComponent } from './songs/songs.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import 'hammerjs'
import { ToastrModule } from 'ngx-toastr';
import { faFacebookF, faTwitter, faGooglePlusG, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import { NgxAudioPlayerModule } from 'Player/ngx-audio-player/src/lib/ngx-audio-player.module';
import { ArtistsService } from './Shared/artists.service';
import { AdminLoginComponent } from "../app/admin/login/admin-login.component";
import { AlbumComponent } from './admin/album/album.component'
import { AddOrUpdateArtistComponent } from './admin/artist/add-or-update-artist/add-or-update-artist.component';
import { AddUpdateAlbumComponent } from './admin/album/add-update-album/add-update-album.component';
import { AddUpdateSongsComponent } from './admin/songs/add-update-songs/add-update-songs.component'
import { ArtistComponent } from './admin/artist/artist.component';
import { AdminSongsComponent } from './admin/songs/songs.component';
import { ViewsongComponent } from './admin/viewsong/viewsong.component';
import { AccountsRoutingModule } from './account/account-routing.module';
import { AudioPlayerService } from 'Player/ngx-audio-player/src/lib/service/audio-player-service/audio-player.service';
import { PlayerService } from './Shared/player.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ResetpasswordComponent } from './admin/resetpassword/resetpassword.component'
import { ArtistResetpasswordComponent } from './admin/artist-resetpassword/artist-resetpassword.component';
import { UserComponent } from './admin/user/user.component';
import { ViewuserprofileComponent } from './admin/viewuserprofile/viewuserprofile.component';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import { FavouriteComponent } from './favourite/favourite.component';
import { AllplaylistComponent } from './allplaylist/allplaylist.component';
import { ViewalbumComponent } from './admin/viewalbum/viewalbum.component';
import { PaginationService } from './Shared/pagination.service';
import { PaginationServiceForAlbum } from './Shared/paginationserviceForAlbum.service';
import { PaginationServiceForSongs } from './Shared/paginationserviceForSongs.service';
import { PaginationServiceForUserService } from './Shared/pagination-service-for-user.service';
import { TobyhomeComponent } from './home/tobyHome/tobyhome/tobyhome.component';
import { ArtistProfileComponent } from './artist/artist-profile/artist-profile.component';
import { AllartistsComponent } from './artist/allartists/allartists.component';
import { AlbumdetailsComponent } from './album/albumdetails/albumdetails.component';
import { AllsongsComponent } from './album/allsongs/allsongs.component';
import { SongsdetailsComponent } from './album/songsdetails/songsdetails.component';
import { AllfavsongsComponent } from './album/allfavsongs/allfavsongs.component'
import { AlbumComponent1 } from './album/album/album.component';
import { FavalbumComponent } from './album/favalbum/favalbum.component'
import { FavdjmixComponent } from './album/favdjmix/favdjmix.component'
import { SettingComponent } from './account/setting/setting.component'
import { ThemeComponent } from './account/theme/theme.component';
import { SuggestionComponent } from './account/suggestion/suggestion.component';
import { ReportComponent } from './account/report/report.component';
import { ChangeLanguageComponent } from './account/change-language/change-language.component';
import { EditUserprofileComponent } from './account/editUserprofile/editUserprofile.component';
import { UserInviteComponent } from './account/userinvite/userinvite.component';
import { ProfileComponent } from './account/profile/profile.component';
import { AboutusComponent } from './account/aboutus/aboutus.component';
import { TermsComponent } from './account/terms/terms.component';
import { PaymentComponent } from './account/payment/payment.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { PlaylistsongsComponent } from './playlist/playlistsongs/playlistsongs.component';
import { FavplaylistComponent } from './playlist/favplaylist/favplaylist.component';
import { OwlModule } from 'ngx-owl-carousel';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ViewartistProfileComponent } from './admin/artist/profile/profile.component';
import { EditArtistprofileComponent } from './admin/artist/editprofile/editprofile.component';
import { SubscriptionplanComponent } from './admin/subscriptionplan/subscriptionplan.component';
import { UpdatesubscriptionplanComponent } from './admin/subscriptionplan/updatesubscriptionplan/updatesubscriptionplan.component';
import { AdminSuggestionComponent } from './admin/admin-suggestion/admin-suggestion.component';
import { AdminProblemsComponent } from './admin/admin-problems/admin-problems.component';
import { UpdateProblemComponent } from './admin/admin-problems/update-problem/update-problem.component';
import { UpdateSuggestionstatusComponent } from './admin/admin-suggestion/update-suggestionstatus/update-suggestionstatus.component';
//import { CKEditorModule } from 'ng2-ckeditor';
import { AdminplaylistComponent } from './admin/adminplaylist/adminplaylist.component';
import { AdminPlDetailsComponent } from './admin/adminplaylist/admin-pl-details/admin-pl-details.component';
import { ArtistreportComponent } from './admin/artistreport/artistreport.component';
import { AddUpdateHowtoComponent } from './admin/admin-howto/add-update-howto/add-update-howto.component';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxBraintreeModule } from 'ngx-braintree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { OwlModule } from 'ngx-owl-carousel';
import { ArtistJoinUsComponent } from './login/artist-join-us/artist-join-us.component';
import { UpgradeSunscriptionComponent } from './account/upgrade-sunscription/upgrade-sunscription.component';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider, AuthService

} from 'angular-6-social-login';
import { EdittermsComponent } from './admin/editterms/editterms.component';
import { DatePipe } from '@angular/common';
import { PlaylistdetailsComponent } from './allplaylist/playlistdetails.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { ArtistMainPageComponent } from './login/artist-main-page/artist-main-page.component';
import { GetAccessComponent } from './login/get-access/get-access.component';
import { ArtistRegisterComponent } from './login/artist-register/artist-register.component';
import { ArtistDashboardComponent } from './home/artist-dashboard/artist-dashboard.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ArtistAlbumComponent } from './home/artist-album/artist-album.component';
import { ArtistSongsComponent } from './home/artist-songs/artist-songs.component';
import { ArtistCommunicationComponent } from './home/artist-communication/artist-communication.component';
import { ArtistHowtoComponent } from './home/artist-howto/artist-howto.component';
import { ArtistNewProfileComponent } from './home/artist-new-profile/artist-new-profile.component';
import { EditArtistProfileComponent } from './home/edit-artist-profile/edit-artist-profile.component';
import { ArtistChangepasswordComponent } from './home/artist-changepassword/artist-changepassword.component';
import { ArtistpageComponent } from './home/artistpage/artistpage.component';
import { AdminHowtoComponent } from './admin/admin-howto/admin-howto.component';
import { AdminTodoListComponent } from './admin/admin-todo-list/admin-todo-list.component';
import { AddUpdateTodolistComponent } from './admin/admin-todo-list/add-update-todolist/add-update-todolist.component';
import { MatChipsModule } from '@angular/material/chips';
import { NewHomeComponent } from './home/new-home/new-home.component';
import { SetHeightDirective } from './home/new-home/set-height.directive';
import { NewDiscoverComponent } from './new-discover/new-discover.component';
import { DiscoverAlbumComponent } from './discover-album/discover-album.component';
import { AdminCommunicationComponent } from './admin/admin-communication/admin-communication.component';
import { ArtistFeedbackComponent } from './home/artist-feedback/artist-feedback.component';
import { FeedbackComponent } from './admin/feedback/feedback.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LikedSongsComponent } from './liked-songs/liked-songs.component';
import { RecommendedSongsComponent } from './recommended-songs/recommended-songs.component';
import { MostPlayedSongsComponent } from './most-played-songs/most-played-songs.component';
import { NewlyAddedSongsComponent } from './newly-added-songs/newly-added-songs.component';
import { MostPlayedAlbumsComponent } from './most-played-albums/most-played-albums.component';
import { MostPlayedArtistComponent } from './most-played-artist/most-played-artist.component';
import { HomePageBannerComponent } from './admin/home-page-banner/home-page-banner.component';
import { AddUpdateHomebannerComponent } from './admin/home-page-banner/add-update-homebanner/add-update-homebanner.component';
import { BannerDetailsComponent } from './banner-details/banner-details.component';
import { ArtistOrAlbumpagesComponent } from './artist-or-albumpages/artist-or-albumpages.component';
import { UserHomesearchComponent } from './home/user-homesearch/user-homesearch.component';
import { MatSelectModule } from '@angular/material/select';
import { HomepageSectionComponent } from './admin/homepage-section/homepage-section.component';
import { AddUpdateHomepagesectionComponent } from './admin/homepage-section/add-update-homepagesection/add-update-homepagesection.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserAccountComponent } from './account/user-account/user-account.component';
import { UseraccountHeaderComponent } from './account/useraccount-header/useraccount-header.component';
import { ChangeUserPasswordComponent } from './account/change-user-password/change-user-password.component';
import { UsermanageplanComponent } from './usermanageplan/usermanageplan.component';
import { ChangePaymentComponent } from './account/change-payment/change-payment.component';
import { UserNotificationSettingsComponent } from './account/user-notification-settings/user-notification-settings.component';
import { EditLoginMethodsComponent } from './account/edit-login-methods/edit-login-methods.component';
import { UpgradeSubscriptionComponent } from './account/upgrade-subscription/upgrade-subscription.component';
import { LikeFavouriteDropdownComponent } from './like-favourite-dropdown/like-favourite-dropdown.component';
import { UserAlbumsComponent } from './user-albums/user-albums.component';
import { UserHomeComponent } from './home/user-home/user-home.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserDiscoverComponent } from './user-discover/user-discover.component';
import {LazyLoadImageModule, scrollPreset} from 'ng-lazyload-image';
import { NotificationComponent } from './admin/notification/notification.component';
import { AddUpdateNotificationComponent } from './admin/notification/add-update-notification/add-update-notification.component';
import { UserOrderHistoryComponent } from './account/user-order-history/user-order-history.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { AdminFaqComponent } from './admin/admin-faq/admin-faq.component';
import { AddUpdateFaqComponent } from './admin/admin-faq/add-update-faq/add-update-faq.component';
// import { CKEditorModule } from 'ckeditor4-angular';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular'
import { MainFooterComponent } from './layout/main-footer/main-footer.component';
import { ManageNotificationComponent } from './admin/notification/manage-notification/manage-notification.component';
import { UserProblemsORsuggestionsComponent } from './admin/user-problems-orsuggestions/user-problems-orsuggestions.component';
import { TopmostsongsComponent } from './topmostsongs/topmostsongs.component';
import { TopfavSongspageComponent } from './home/topfav-songspage/topfav-songspage.component';
import { TopCategorySongsComponent } from './home/top-category-songs/top-category-songs.component';
import { LatestArAlSngComponent } from './home/latest-ar-al-sng/latest-ar-al-sng.component';
import { VerifyEmailComponent } from './login/verify-email/verify-email.component';
import {MatMenuModule} from '@angular/material/menu';
// import { VgCoreModule } from '@videogular/ngx-videogular/core';
// import { VgControlsModule } from '@videogular/ngx-videogular/controls';
// import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
// import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';



export const createTranslateLoader = (http: HttpClient) => {

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        //provider: new FacebookLoginProvider('925057679424909')
        provider: new FacebookLoginProvider('443367283323356')
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('205179494078-k8mqsb06lmojghv07q9ajpttmogkt8ja.apps.googleusercontent.com')
        // provider: new GoogleLoginProvider('160289112560-73ch0n37l3fviaipidf5jht8m85v3eh8.apps.googleusercontent.com')
      }
    ]

  );
  return config;
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DiscoverComponent,
    TobyHomeScreenComponent,
    HeaderComponent,
    FooterComponent,
    RightsideMenuComponent,
    LeftsideMenuComponent,
    PageNotFoundComponent,
    SongsComponent,
    AdminLoginComponent,
    AlbumComponent,
    AddOrUpdateArtistComponent,
    ArtistComponent,
    AddUpdateAlbumComponent,
    AddUpdateSongsComponent,
    AdminSongsComponent,
    ResetpasswordComponent,
    ArtistResetpasswordComponent,
    UserComponent,
    ViewuserprofileComponent,
    ChangepasswordComponent,
    FavouriteComponent,
    AllplaylistComponent,
    ViewalbumComponent,
    ViewsongComponent,
    TobyhomeComponent,
    ArtistProfileComponent,
    AllartistsComponent,
    AlbumdetailsComponent,
    AllsongsComponent,
    SongsdetailsComponent,
    AllfavsongsComponent,
    FavalbumComponent,
    FavdjmixComponent,
    SettingComponent,
    PaymentComponent,
    TermsComponent,
    ThemeComponent,
    SuggestionComponent,
    ChangeLanguageComponent,
    ReportComponent,
    EditUserprofileComponent,
    AboutusComponent,
    ProfileComponent,
    PlaylistComponent,
    PlaylistsongsComponent,
    FavplaylistComponent,
    AlbumComponent1,
    ViewartistProfileComponent,
    EditArtistprofileComponent,
    SubscriptionplanComponent,
    UpdatesubscriptionplanComponent,
    AdminSuggestionComponent,
    AdminProblemsComponent,
    UpdateProblemComponent,
    EdittermsComponent,
    UpdateSuggestionstatusComponent,
    ArtistJoinUsComponent,
    UpgradeSunscriptionComponent,
    AdminplaylistComponent,
    AdminPlDetailsComponent,
    PlaylistdetailsComponent,
    ArtistreportComponent,
    UserInviteComponent,
    ArtistMainPageComponent,
    GetAccessComponent,
    ArtistRegisterComponent,
    ArtistDashboardComponent,
    ArtistAlbumComponent,
    ArtistSongsComponent,
    ArtistCommunicationComponent,
    ArtistHowtoComponent,
    ArtistNewProfileComponent,
    EditArtistProfileComponent,
    ArtistChangepasswordComponent,
    ArtistpageComponent,
    AdminHowtoComponent,
    AddUpdateHowtoComponent,
    AdminTodoListComponent,
    AddUpdateTodolistComponent,
    NewHomeComponent,
    SetHeightDirective,
    NewDiscoverComponent,
    DiscoverAlbumComponent,
    AdminCommunicationComponent,
    ArtistFeedbackComponent,
    FeedbackComponent,
    LikedSongsComponent,
    RecommendedSongsComponent,
    MostPlayedSongsComponent,
    NewlyAddedSongsComponent,
    MostPlayedAlbumsComponent,
    MostPlayedArtistComponent,
    HomePageBannerComponent,
    AddUpdateHomebannerComponent,
    BannerDetailsComponent,
    ArtistOrAlbumpagesComponent,
    UserHomesearchComponent,
    HomepageSectionComponent,
    AddUpdateHomepagesectionComponent,
    UserAccountComponent,
    UseraccountHeaderComponent,
    ChangeUserPasswordComponent,
    UsermanageplanComponent,
    ChangePaymentComponent,
    UserNotificationSettingsComponent,
    EditLoginMethodsComponent,
    UpgradeSubscriptionComponent,
    LikeFavouriteDropdownComponent,
    UserAlbumsComponent,
    UserHomeComponent,
    UserDiscoverComponent,
    NotificationComponent,
    AddUpdateNotificationComponent,
    UserOrderHistoryComponent,
    AdminFaqComponent,
    AddUpdateFaqComponent,
    MainFooterComponent,
    ManageNotificationComponent,
    UserProblemsORsuggestionsComponent,
    TopmostsongsComponent,
    TopfavSongspageComponent,
    TopCategorySongsComponent,
    LatestArAlSngComponent,
    VerifyEmailComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    SocialLoginModule,
    BrowserModule,
    InfiniteScrollModule,
    LoginModule,
    AccountModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    RouterModule,
    NgxAudioPlayerModule,
    FontAwesomeModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AppRoutingModule,
    AccountsRoutingModule,
    NgxSpinnerModule,
    CKEditorModule,
    NgxBraintreeModule,
    MatTooltipModule,
    OwlModule,
    NgxCaptchaModule,
    MatBadgeModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatChipsModule,
    MatSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatExpansionModule,
    NgxSkeletonLoaderModule,
    LazyLoadImageModule.forRoot({
      preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    }),
    ScrollingModule,
    MatMenuModule

    // VgCoreModule,
    // VgControlsModule,
    // VgOverlayPlayModule,
    // VgBufferingModule,
  ],
  providers: [MatDatepickerModule,
    HttpClient,
    LoadScriptService,
    LoginService,
    ArtistsService,
    ConfigService,
    HomeService,
    AudioPlayerService,
    PlayerService,
    PaginationService, PaginationServiceForAlbum, PaginationServiceForSongs, PaginationServiceForUserService, DatePipe,
    AuthService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ]
  ,
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {
    library.add(faFacebookF, faTwitter, faGooglePlusG, faLinkedinIn);
  }
}
